ul,
ol {
    ul,
    ol {
        counter-reset: list;
    }
}

li {
    &::before {
        margin-right: 5px;

        ol > & {
            content: counter(list) '.';
        }

        &,
        ul > & {
            content: '\2022';
        }
    }
}
