.SectionBorder {
    &::before {
        content: ' ';
        height: 1px;
        width: 74%;
        background: $primary10;
        position: absolute;
        margin: 0 13%;
    }
    &_type_top {
        &::before {
            top: -1px;
        }
    }
}
