/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-criteria-background: #{$default-secondary-base-color};
    --search-criteria-label-color: #{$default-secondary-dark-color};
    --search-criteria-search-term-color: #{$default-secondary-base-color};
    --search-item-divider-color: #{$default-secondary-dark-color};
    --search-item-title-color: #{$default-secondary-dark-color};
}

.BlogSearchOverlay {
    --placeholder-animation: placeholderAnimation 2s linear infinite;
    margin-top: 4rem;
    right: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    max-height: 40vh;
    transition-duration: 200ms;
    transition-property: width, opacity, transform, border-color;
    transform: scaleY(0);
    transform-origin: 0 0;
    text-align: left;
    will-change: width, transform, border;
    border-color: var(--search-criteria-background);
    border-style: solid;

    @include desktop {
        top: calc(100% - 1px);
        margin-top: 0;
    }

    &_isVisible {
        transform: scaleY(1);
        border-width: 0 1px 1px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 2px);
        height: 1px;
    }

    &-Criteria {
        margin-bottom: 0;
        background-color: var(--search-criteria-background);
        color: var(--search-criteria-label-color);
        padding: 0;
        max-height: 0;

        opacity: 0;
        transition: opacity 200ms;
        will-change: max-height, padding, opacity;
        overflow: hidden;

        &_isVisible {
            opacity: 1;
            padding: .7rem 1rem .5rem;
            max-height: 100px;
            height: auto;
        }

        strong {
            margin-left: 5px;
        }
    }

    &-Results {
        padding: 1rem;
        border-color: var(--search-item-divider-color);
        max-height: 60vh;
    }

    &-Item {
        padding: .5rem 0;
        margin-bottom: 0;

        &::before {
            content: none;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 1rem;
            height: 1px;
            background-color: var(--search-item-divider-color);
        }

        &:last-child {
            margin-bottom: 0;

            &::after {
                content: none;
            }
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 6rem auto;
        grid-column-gap: 1rem;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-Title {
        color: var(--search-item-title-color);
        font-size: 1.1rem;
        font-weight: 600;
        max-width: calc(100vw - 7rem);

        &_isLoaded {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &-Link {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }
}
