/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ReportItem {
    font-family: $font-filson-soft;

    &_variant {
        &_default {
            display: grid;
            grid-template-rows: max-content;
            background: $primary5;
            padding: 20px;

            @include desktop {
                padding: 25px 45px 55px 45px;
            }

            .ReportItem {
                &-ContentWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                &-Description {
                    padding-bottom: 48px;

                    @include desktop {
                        padding-bottom: 48px;
                    }
                }

                &-Button {
                    font-size: 16px;
                    padding: 14px 48px;
                    margin: auto auto 0;

                    @include desktop {
                        margin-top: auto;
                    }
                }
            }
        }

        &_short {
            .ReportItem {
                &-TitleWrapper {
                    padding: 5px 0;

                    svg {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        fill: $primary50;
                        margin-left: 10px;
                    }
                }

                &-Title {
                    font-size: 15px;
                    font-weight: 300;
                    color: $neutral80;
                    text-align: center;
                    width: 100%;

                    &:hover {
                        color: $primary50;
                    }
                }
            }
        }
    }
    &-Title {
        text-transform: lowercase;
        text-align: center;
        width: 100%;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}
