.HeroImage {
    position: relative;


    &-Header{
        @include headerHeroImage;
    }

    img {
        object-fit: cover;
    }
}

@include desktop {
    .HeroImage {
        @include aspect-ratio(37.44%, "& > img");
    }
}

@include mobile {
    .HeroImage{
        margin-top: var(--header-height);

        @include aspect-ratio(37.44%, "& > img");
    }
}
