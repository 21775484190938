/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ShowMore {
    &-Button.Button_priority_primary {
        margin-top: 30px;
        padding-top: 0;
        padding-bottom: 0;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &-Content {
        position: relative;

        &:not(&_isOpen) {
           &::after {
               content: "";
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
               width: 100%;
               height: 92px;
           }
        }
    }
}
