/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuizRecommendation {
    background-color: $primary5;
    padding-bottom: 32px;

    &-Header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 24px;
        color: var(--neutral-80, #3C404B);
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        text-transform: none;

        @include desktop {
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 16px;
        }

        span {
            color: var(--primary-90, #70A3CA);
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 45px;
        }
    }

    &-Tick {
        background-image: url('../../assets/svg/tick.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        margin-top: 28px;
        width: 32px;
        height: 26px;
    }

    &-Wrapper {
        font-family: $font-filson-soft;
        max-width: 1440px;
        margin: 0 auto 0;
        padding-top: var(--header-height);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 28px;
    }

    &-Products{
        display: grid;
        grid-gap: 100px;

        @include desktop {
            max-width: 1000px;
            margin-inline: auto;
        }

        @include before-desktop{
            grid-column-gap: 20px;
            grid-row-gap: 80px;
        }

        @include before-desktop{
            grid-template-columns: 1fr;
        }
    }

    &-NoProducts {
        text-transform: none;
        text-align: center;
        color: $error80;
    }

    &-ImageWithIcons,
    &-Content {
        @include desktop {
            padding: 48px 40px;
        }

        img {
            max-height: 493px;
            object-fit: contain;
        }
    }

    &-Product {
        margin: 0 16px;
        padding: 0 20px 0;
        gap: 24px;
        display: grid;
        font-weight: 700;
        color: $neutral90;
        background-color: $neutral5;
        box-shadow: 0px 0px 40px 0px rgba(82, 149, 200, 0.18);
        border-radius: 25px;

        @include before-desktop {
            align-items: center;
            padding-block-end: 20px;
        }

        @include desktop {
            grid-template-columns: repeat(2, 50%);
        }

        > img {
            width: auto;
            max-width: 100%;
            margin-top: 32px;
        }

        .ProductIcons {
            justify-content: center;

            > div {
                margin-bottom: 0;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .ProductShortDescription {
            margin-bottom: 24px;

            .ProductShortDescription-Content {
                display: none;
                &:first-of-type {
                    display: block;
                    margin: 0;
                    color: $neutral80;
                    text-align: center;
                    font-family: $font-filson-soft;
                    font-size: 14px;
                    font-weight: 350;
                    line-height: 150%;

                    @include desktop {
                        text-align: left;
                    }
                }
            }
            .CMSList {
                max-width: initial;

                .CMSList-Wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    padding: 20px 0;
                    border-top: 1px solid $primary20;
                    border-bottom: 1px solid $primary20;
                    .CMSList-Item {
                        display: flex;
                        gap: 12px;
                        padding: 0;
                        text-align: left;

                        p {
                            font-weight: 350;
                            line-height: 150%;
                        }

                        .CMSList-ItemTick {
                            flex-shrink: 0;
                            padding: 0;
                            width: 16px;
                            height: 24px;
                            background-size: 12px 8px;
                            background-position: left 8px;
                        }
                        .CMSList-ItemContent {
                            color: $neutral80;
                            font-family: $font-filson-soft;
                            font-size: 13px;
                            line-height: 150%;
                            font-weight: 350;
                            span {
                                font-weight: 350 !important;
                            }
                        }
                    }
                }
            }
        }

        &:before {
            position: absolute;
            top: -32px;
            content: '';
            width: 100%;
            height: 64px;
            background: top no-repeat url('../../assets/svg/white-dock.svg');
        }

        .ProductNumber {
            position: absolute;
            left: 50%;
            top: -22px;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $primary50;
            color: $neutral5;
            font-size: 15px;
            font-weight: 700;
            line-height: 150%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-ProductName {
        margin: 0 0 16px;
        color: $neutral80;
        text-align: center;
        font-family: $font-filson-soft;
        font-size: 21px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: 0.42px;

        @include desktop {
            text-align: left;
        }
    }

    &-ButtonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 24px;
    }

    &-ReadMoreButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        color: $neutral50;
        background-color: $neutral5;
        padding: 8px 16px;
        min-height: 56px;
        max-width: 312px;
        width: 100%;
        text-align: center;
        font-family: $font-filson-soft;
        font-size: 15px;
        line-height: 125%;
        font-weight: 400;
    }
}
