/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    border: none;
    &-Heading {
        font-family: "filson-soft", sans-serif;
    }
}

@include desktop{
    .ExpandableContent {
        &-Heading {
            font-size: 16px;
            font-weight: 700;
        }

        &-Content {
            margin-block-start: 19px;
        }
    }
}

@include tablet{
    .ExpandableContent {
        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }

        &-Button {
            padding-block: 14px;
        }

        &-Content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                @include expanded-content;
            }
        }

        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}
