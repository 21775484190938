.LandingProduct {
    font-family: $font-filson-soft;

    @include desktop {
        padding-top: calc(var(--header-height));
    }

    img {
        height: auto;
        width: auto;
        max-width: 100%;
    }

    .Button {
        font-family: $font-filson-soft;
        font-weight: 500;
        text-align: center;

        @include desktop {
            min-width: 286px;
        }

        @include before-desktop {
            min-width: 286px;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-Header {
        display: flex;
        min-height: calc(100vh - var(--header-height));

        &Container {
            @include container;

            display: flex;
            align-items: center;
            height: auto;
            width: 100%;
        }

        &Content {
            @include desktop {
                max-width: 432px;
            }

            .TypographyHeader {
                margin-bottom: 32px;
            }

            z-index: 1;
        }

        img {
            object-position: center;
            object-fit: cover;
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-Video {
        background-image: url("../../assets/images/lp-video-bg.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100%;
        text-align: center;
        margin-bottom: 100px;

        @include tablet {
            margin-bottom: 80px;
            background-image: url("../../assets/images/lp-video-bg-mobile.png");
        }

        &Container {
            @include container;
        }

        &Text {
            display: grid;
            grid-gap: 0 48px;
            grid-template-columns: 1fr 1fr;
            text-align: left;
            padding-block: 100px 80px;

            @include tablet {
                grid-template-columns: 1fr;
                padding-block: 48px 40px;
            }

            .TypographyHeader {
                margin-block: 0 16px;
            }
        }

        &List {
            padding-inline: 154px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 32px;

            @include tablet {
                grid-template-columns: repeat(3, 40%);
                margin-inline: -32px;
                padding-inline: 32px;
                overflow-x: auto;
                scroll-snap-type: mandatory;
                scroll-snap-type: x mandatory;

                & {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @include mobile {
                grid-template-columns: repeat(3, 80%);
                margin-inline: -16px;
                padding-inline: 16px;
            }
        }

        &Item {
            overflow: hidden;
            border-radius: 20px;

            @include tablet {
                scroll-snap-align: center;
            }
        }

        .Button {
            margin-top: 48px;

            @include tablet {
                margin-top: 24px;
            }
        }
    }

    &-Product {
        display: grid;
        grid-gap: 40px 48px;
        grid-template-columns: 1fr 1fr;
        background-color: $primary5;
        border-radius: 20px;
        padding: 71px 108px;
        margin-bottom: 126px;

        @include tablet {
            margin-bottom: 80px;
        }

        @include tablet {
            grid-template-columns: 1fr;
            padding: 40px 32px;
        }

        .Button {
            margin-top: 16px;

            &_desktop {
                @include tablet {
                    display: none;
                }
            }

            &_mobile {
                @include desktop {
                    display: none;
                }
            }
        }

        &Image {
            justify-self: center;
        }

        .TypographyHeader {
            margin-block: 0 16px;
        }

        &Container {
            @include container;
        }
    }

    &-Footer {
        @include container;

        display: grid;
        grid-gap: 40px 48px;
        grid-template-columns: 1fr 1fr;

        @include tablet {
            grid-template-columns: 1fr;
        }

        img {
            width: 100%;
        }

        .TypographyHeader {
            margin-block: 60px 16px;

            @include tablet {
                margin-top: 0;
            }
        }

        .Button {
            margin-left: auto;
            margin-top: 48px;

            @include tablet {
                margin-top: 16px;
            }
        }
    }
}
