/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.InstallPromptAndroid {
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 4;
    width: 100%;
    margin: 0 auto;
    background-color: #F5F5F5;
    padding-bottom: 15px;
    &-Wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        margin-right: 25px;
    }
    &-Content {
        text-align: center;
        margin: 0 auto;
        padding: 10px 0;
    }
    &-Close {
        display: flex;
        justify-content: flex-end;
    }
    Button:last-child {
        padding: 11px 48px 11px;
    }
}
