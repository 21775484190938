.LpEcoInfo {
    margin-block: 77px 64px;

    @include desktop {
        margin-block: 150px;
    }

    .LpPrimaryWrapper {
        @include container
    }

    &-Image {
        @include desktop {
            order: -1;
        }
    }

    .TypographyHeader {
        letter-spacing: initial;
        margin-block: 0;

        span {
            color: $primary90;
        }

        & + .TypographyHeader {
            margin-top: 16px;

            @include desktop {
                margin-top: 24px;
            }
        }

        &.TypographyHeader_heading_h3 {
            color: $primary90;
        }
    }

    .TypographyParagraph {
        margin-top: 8px;
    }

    .LpEco-List li {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;

        & + li {
            margin-top: 16px;
        }
    }

    &-List {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr;

        @include desktop {
            gap: 45px;
        }
    }

    &-Grid {
        display: grid;
        align-items: center;
        gap: 0;
        grid-template-columns: 1fr;

        @include desktop {
            gap: 45px;
            grid-template-columns: 1fr 1fr;
        }
    }
}
