/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * filson-pro:
 *   - http://typekit.com/eulas/0000000000000000000157bf
 *   - http://typekit.com/eulas/0000000000000000000157c0
 *   - http://typekit.com/eulas/0000000000000000000157c1
 *   - http://typekit.com/eulas/0000000000000000000157c2
 * filson-soft:
 *   - http://typekit.com/eulas/00000000000000003b9ade5c
 *   - http://typekit.com/eulas/00000000000000003b9ade62
 *   - http://typekit.com/eulas/00000000000000003b9ade59
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-08-09 08:24:40 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=lds0lsk&ht=tk&f=24561.24562.24563.24564.28747.28754.28760&a=78895822&app=typekit&e=css");

@font-face {
    font-family:"filson-pro";
    src:url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/c12453/0000000000000000000157bf/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
    font-family:"filson-pro";
    src:url("https://use.typekit.net/af/125ffe/0000000000000000000157c0/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/125ffe/0000000000000000000157c0/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/125ffe/0000000000000000000157c0/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
    font-family:"filson-pro";
    src:url("https://use.typekit.net/af/c37133/0000000000000000000157c1/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/c37133/0000000000000000000157c1/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/c37133/0000000000000000000157c1/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
    font-family:"filson-pro";
    src:url("https://use.typekit.net/af/828f71/0000000000000000000157c2/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/828f71/0000000000000000000157c2/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/828f71/0000000000000000000157c2/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=i3&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/0ceada/00000000000000003b9ade5c/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/5fe107/00000000000000003b9ade62/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
}
@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:350;
}

@font-face {
    font-family:"filson-soft";
    src:url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/l?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/d?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/672b42/00000000000000003b9ade59/27/a?primer=e6066692ab328d1a92bb155659ed6609b693e1e352f0e8982e4224719b13bdfb&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.tk-filson-pro { font-family: "filson-pro",sans-serif; }
.tk-filson-soft { font-family: "filson-soft",sans-serif; }
