.ImagesWithDescCMS {
    &-Landing {
        padding: 50px 100px;
        margin: 0 auto 0 auto;
    }

    &-LandingAlternative {
        padding: 100px;
        margin: 0 auto 0 auto;
    }

    &-LandingBlog {
        padding: 50px 100px;
    }

    max-width: 1440px;
    margin: 0 auto 80px auto;

    &-Block {
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        grid-gap: 55px;
        align-items: center;
        grid-auto-flow: column;

        &-LandingBlog {
            margin-top: 0 !important;
        }

        &:not(:last-of-type) {
            margin-bottom: 60px;
        }

        &_isReverse {
            grid-template-columns: 1fr 1.4fr;

            .WhyOsavi-Content {
                padding-right: 0;
                padding-left: 84px;
            }
        }
    }

    &-Content {
        padding: 0 75px 0 25px;
    }

    &-Image {
        width: 100%;
        height: auto;

        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    &-Button {
        margin-top: 30px;
    }

    &_secondary, &_wide {
        max-width: initial;

        .TypographyHeader {
            margin-bottom: 20px;
        }

        .ImagesWithDescCMS {

            &-Block {
                max-width: 1120px;
                margin-left: auto;
                margin-right: auto;
                grid-template-columns: 0.6fr 1fr;
                grid-gap: 50px;

                &:not(:last-of-type) {
                    margin-bottom: 120px;
                }

                &:first-of-type {
                    margin-top: 60px;
                }

                &_isReverse {
                    grid-template-columns: 1fr 0.6fr;

                    .ImagesWithDescCMS-Content {
                        padding-right: 50px;
                        padding-left: 100px;
                    }
                }
            }

            &-Text {
                font-weight: 400;
            }

            &-Content {
                padding-right: 120px;
            }
        }
    }

    &-ButtonWrap {
        margin: 25px 0 0;
    }

    &_wide {
        @include desktop {
            padding: 0 25px;
        }

        .ImagesWithDescCMS {

            &-Block {
                max-width: 1630px;
                grid-gap: 0;
                @include desktop {
                    grid-gap: 50px;
                }

                &:not(:last-of-type) {
                    margin-bottom: 60px;
                    @include desktop {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &-ShowMore {
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            background: $advanced;
        }

        a {
            background: $white;
            margin-left: auto;
            margin-right: auto;
            z-index: 0;
        }
    }
}

@include tablet {
    .ImagesWithDescCMS {
        &-Block {
            display: flex;
            flex-direction: column;
            grid-gap: 0;
            max-width: 73%;

            &:not(:last-of-type) {
                margin-bottom: 90px;
            }

            &_isReverse {
                margin-left: auto;
                flex-direction: column-reverse;

                .ImagesWithDescCMS-Content {
                    padding-left: 0;
                    padding-right: 24px;
                }
            }
        }

        &-Content {
            padding: 0 25px 0 25px;
        }

        &-SubHeader {
            max-width: 90%;
        }

        &-Image {
            margin-bottom: 20px;
        }

        &_secondary {
            .ImagesWithDescCMS {
                &-Block {
                    max-width: 73%;
                    margin-left: 0;

                    &_isReverse {
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@include mobile {
    .ImagesWithDescCMS {

        &-Landing {
            padding: 0;
        }

        &-Block {
            max-width: 100%;

            &:first-of-type {
                margin-top: 40px;
            }

            &_isReverse {
                .ImagesWithDescCMS-Content {
                    padding: 0 24px;
                }
            }
        }

        &-Image {
            margin-bottom: 10px;
        }

        &-Button {
            margin-top: 20px;
        }

        &_secondary {
            .ImagesWithDescCMS {
                &-Block {
                    max-width: 100%;

                    &_isReverse {
                        .ImagesWithDescCMS-Content {
                            padding: 0 24px;
                        }
                    }
                }

                &-Content {
                    padding: 0 24px;
                }
            }
        }
    }
}
