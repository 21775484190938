.ProductOpinion {
    padding: 100px 0;
    background-position: left 200px;
    background-repeat: no-repeat;
    background-image: url("../../assets/svg/product-opinion-bg.svg");

    @include tablet {
        padding-block: 60px;
        background-position: top left;
        background-image: url("../../assets/images/opition_bg_mobile.png");
    }

    &-Header {
        text-align: center;
        margin-bottom: 87px;
        margin-top: 0;

        strong {
            color: $primary50;
            font-weight: inherit;
        }
    }

    .SliderWidget {
        &_isOpinion {
            .TypographyHeader_heading_h3 {
                margin-bottom: 8px;
            }
        }

        &-OpinionPosition {
            a {
                font-size: inherit;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}
