/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LotResult {
    max-width: 1130px;
    margin: 0 auto;
    padding: 119px 15px 100px 15px;
    width: 100%;
    font-family: "filson-soft",sans-serif;

    .TypographyParagraph_color_blue {
        color: $primary50;
    }
    &-Hero {
        display: flex;
        margin: 0 auto;
        max-width: 1080px;
    }
    &-Info {
        margin-right: 82px;
        margin-left: 40px;
    }
    &-IssueDate {
        display: flex;
    }
    &-Title {
        margin-right: 8px;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 500;
    }
    &-Date {
        font-size: 17px;
        font-weight: 500;
    }
    &-Product {
        margin-bottom: 0;
        line-height: 1.2;
        margin-top: 50px;
        font-size: 30px;
        letter-spacing: unset;
        font-weight: 350;
        &_bigger {
            font-size: 40px;
        }
    }
    &-Lot {
        display: flex;
        margin: 60px 0;
    }
    &-Header {
        margin-right: 7px;
        font-size: 17px;
        font-weight: 350;
    }
    &-Number {
        font-size: 17px;
        font-weight: 700;
    }
    &-Details {
        box-shadow: 0 0 80px rgba(82,149,200,.18);
        padding: 15px;

    }
    &-Capacity {
        display: flex;
        .Size {
            font-size: 17px;
            text-transform: uppercase;
            margin-right: 8px;
            font-weight: 700;
        }
        .Value {
            font-size: 17px;
            font-weight: 700;
        }
    }
    &-EndDate {
        display: flex;
        .Best {
            font-size: 17px;
            text-transform: uppercase;
            margin-right: 8px;
            font-weight: 700;
        }
        .End {
            font-size: 17px;
            font-weight: 700;
        }
    }
    &-Image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-ProductImg {
        img {
            height: 330px;
            width: unset;
        }
    }
    &-ImageDisclaimer {
        font-weight: 700;
        font-size: 10px;
    }
    &-ContentImg {
        font-size: 16px;
        font-weight: 500;
        margin-top: 22px;
    }
    .ResearchTypes {
        max-width: 1000px;
        margin: 35px auto;
        border: 2px solid $primary50;
        padding: 35px 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-Item {
            display: flex;
            flex-wrap: wrap;
            line-height: 1;
            gap: 8px;
            &-Name {
                font-size: 30px;
                font-weight: 350;
                line-height: 1.2;
            }
            &-Value {
                font-size: 30px;
                font-weight: 500;
                color: #71B790;
                line-height: 0.8;
                &::after {
                    content: '✓';
                    margin-left: 10px;
                    font-size: 35px;
                    font-weight: 700;
                }
            }
        }
    }

    &-Certificate {
        &Wrapper {
            max-width: 1000px;
            margin: 20px auto 55px;
        }

        &Heading {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @include tablet {
                flex-direction: column;
                justify-content: center;
            }

            img {
                width: 55px;
            }
        }

        &Description {
            margin-left: 30px;
            width: calc(100% - 94px);

            @include tablet {
                text-align: center;
                margin: 25px 15px;
            }

            p {
                font-size: 16px;
            }

            img {
                width: auto;
                max-height: 50px;
            }
        }

        &Lab {
            img {
                width: auto;
                max-height: 70px;
                padding: 15px 0;

                @include tablet {
                    margin: 0 auto;
                    display: block;
                }
            }
        }

        &NextLab {
            margin-top: 10px;

            img {
                max-width: 100%;
                height: auto;

                @include tablet {
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }

    &-CertificateHeading {
        font-weight: 500;
        font-size: 30px;
        text-align: center;
        letter-spacing: unset;
    }

    .LotResult-TooltipContainer {
        position: relative;
        cursor: pointer;

        &:hover {
            &::after {
                display:block;
            }
        }

        &::after {
            content: attr(data-tooltip);
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            transition-delay: 250ms;
            transition-duration: 100ms;
            left:100%;
            margin-left: 20px;
            background-color:#FFFFFF;
            border: 1px solid #9EC5DF;
            color: #000000;
            text-align: left;
            box-shadow: 0 0 80px rgba(82, 149, 200, 0.18);
            padding: 5px 15px;
            width: max-content;
            max-width: 500px;
            font-size: 15px;
            z-index: 1000;
            word-break: break-word;

            display: none;
        }
    }

    &-ProductAdditionalFeatures {
        color: $black;
        background: $primary50;
        padding: 10px 30px;
        border-radius: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: fit-content;
        grid-gap: 10px 30px;

        @include mobile {
            grid-gap: 10px 20px;
            padding: 10px 20px;
        }

        &Wrapper {
            padding: 50px 0;

            @include mobile {
                padding: 30px 0;
            }
        }

        &Item {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            width: min-content;

            @include mobile {
                font-size: 11px;
            }
        }
    }

    .LotTable-Wrapper {
        max-width: 950px;
        margin: 0 auto 55px;

        @include mobile {
            margin-bottom: 35px;
        }
    }

    .AbberviationsKey {
        &-Wrapper {
            padding: 40px;
            box-shadow: 0 0 15px 0 $neutral30;
            display: grid;
            grid-template-columns: 235px auto;
            grid-gap: 15px;
            max-width: 950px;
            margin: 0 auto;

            @include mobile {
                padding: 30px 20px;
                grid-template-columns: 1fr;
            }
        }

        &-Heading {
            font-size: 24px;
            color: $primary50;
            display: flex;
            align-items: center;
        }

        &-Grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
            grid-gap: 15px 35px;

            @include mobile {
                grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            }
        }

        &-Item {
            display: flex;
            font-size: 13px;

            strong {
                white-space: nowrap;
                padding-right: 5px;
            }

            span {
                font-weight: 300;
            }
        }
    }
}

@include tablet {
    .LotResult {
        padding: 119px 30px 100px 30px;
        &-ImageDisclaimer {
            text-align: center;
        }

        &-Hero {
            flex-direction: column;
        }
        &-Image {
            margin-top: 28px;
        }
        &-Product {
            font-size: 35px;
            &_bigger {
                font-size: 40px;
            }
        }
        .ResearchTypes {
            text-align: center;
            &-Item {
                &-Name {
                    font-size: 24px;
                }
                &-Value {
                    font-size: 24px;
                    line-height: 0.5;
                }
            }
        }
    }
}

@include mobile {
    .LotResult {
        .ResearchTypes {
            gap: 35px;
            &-Item {
                &-Value, &-Name {
                    text-align: center;
                    width: 100%;
                }
            }
        }
        &-IssueDate {
            flex-direction: column;
        }
        &-Product {
            font-size: 32px;
        }
        &-Form, &-Number {
            font-size: 27px;
        }
        &-Header {
            font-size: 28px;
        }
        &-Info {
            margin: 0;
        }
        &-Lot {
            flex-direction: column;
            margin-bottom: 42px;
        }
        &-Capacity {
            flex-direction: column;
            margin-bottom: 10px;
            .Size, .Value {
                font-size: 17px;
            }
        }
        &-EndDate {
            flex-direction: column;
            .Best, .End {
                font-size: 21px;
            }
        }
    }
}
@include mobile-xs {
    .LotResult {
        padding: 119px 15px 100px 15px;
    }
}
