.LpPrimaryForYou {
    margin-top: 130px;

    @include desktop {
        margin-top: 110px;
    }

    &-Wrapper {
        @include container;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 48px;

        @include desktop {
            margin-bottom: 20px;
        }
    }

    .LpPrimaryGrid {
        grid-template-columns: 1fr 1fr;
    }

    &-Content {
        @include desktop {
            align-self: center;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 24px;
        }

        p {
            margin-bottom: 24px;

            @include desktop {
                margin-bottom: 32px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-Vegan {
        padding-inline: 14px;
        margin-inline: -14px;
        margin-top: 32px;
        display: grid;
        grid-template-columns: 50px 825px;
        overflow: auto;
        gap: 32px;
        img {
            width: auto;
            max-width: 100%;
        }
    }
}
