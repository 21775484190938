:root {
    --lp-primary-hero-background: #{$nature25};
    --lp-primary-hero-desktop-default-padding-bottom: 46px;
    --lp-primary-hero-desktop-padding-bottom: 231px;
}

.LpPrimaryHero {
    padding-top: 16px;
    padding-bottom: 60px;
    background-color: var(--lp-primary-hero-background);

    @include before-mobile {
        padding-top: calc(var(--header-height) + 16px);
    }

    @include desktop {
        padding-top: calc(var(--header-height, 0px) + 110px);
        padding-bottom: calc(var(--lp-primary-hero-desktop-padding-bottom, 0)  + var(--lp-primary-hero-desktop-default-padding-bottom, 0));
    }

    &-Grid {
        @include container;

        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;

        @include desktop {
            grid-template-columns: 47% auto;
            grid-gap: 53px;
        }
    }

    &-Logo {
        padding-bottom: 24px;

        & > * {
            width: auto;
            height: 59px;
        }

        @include desktop {
            padding-bottom: 32px;

            & > * {
                width: auto;
                height: 74px;
            }
        }
    }

    &-Content {
        @include desktop {
            max-width: 538px;
        }

        .text {
            max-width: 710px;

            .Hero-Paragraph {
                margin-bottom: 19px;
            }
        }
    }

    .TypographyHeader {
        margin-bottom: 32px;
    }

    &-Btn {
        & > * {
            text-align: center;
            width: 100%;
        }

        @include desktop {
            margin-top: 32px;

            & > * {
                width: auto;
            }
        }
    }
}
