.LandingContainerCMS {
    &_alternative {
        background-color: $nature;
    }
    &_advanced {
        background-color: $primary5;
    }
}

.LandingWrapper {
    max-width: 1490px;
    margin: 0 auto;
    padding: 0 25px;
}
