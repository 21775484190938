/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

$checkbox-size: 22px;

.QuizNewsletterForm {
    width: 100%;

    &-SubmitButtonContainer {
        display: flex;
        justify-content: center;
    }

    .FieldForm {
        &-Fields {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin: 0;
        }
        &-Fieldset {
            display: flex;
            flex-direction: column;
            gap: 24px;

            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }
        }
    }

    .Field {
        margin: 0;

        &-CheckboxLabel {
            padding-left: 37px;
        }

        &_type_text {
            input {
                &::placeholder {
                    color: $neutral60;
                }

                height: 48px;
                padding: 10px 21px;
                width: 100%;
                font-family: $font-filson-soft;
                font-size: 15px;
                font-weight: 400;
                line-height: 150%;
                border-radius: 10px;
                border: 1px solid $primary20;
                background-color: $neutral5;
            }
        }

        &_type_checkbox {
            &:not(:first-of-type) {
                margin-top: 16px;
            }

            [type='checkbox']:checked + .input-control {
                background-color: $primary50;
            }

            .input-control {
                border: $primary50 solid 1px;
                width: $checkbox-size;
                height: $checkbox-size;
                margin-left: 0;
                border-radius: 5px;

                &::after {
                    left: 4px;
                    top: 8px;
                    width: 2px;
                    height: 2px;
                }
            }
        }

        &-Message {
            margin-left: 0;
        }
    }

    .Consents-AgreementCMS {
        *,  p {
            color: $neutral70;
            font-family: $font-filson-soft;
            font-size: 11px;
            font-weight: 350;
            line-height: 150%;
        }

        a {
            color: $primary90;
            font-family: $font-filson-soft;
            font-size: 11px;
            font-weight: 350;
            line-height: 150%;
        }
    }
}
