/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --header-height: 80px;
    --header-nav-height: 80px;
    --header-total-height: 80px;
    --header-logo-height: 45px;
    --header-logo-width: 105px;
    --quiz-header-height: 50px;
}

.Header {
    padding: 0 30px 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    transition: background-color .1s ease-in-out;

    &.Header_name_popup {
        display: none;
    }

    &-Wrapper {
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        position: fixed;
    }

    &_showMobileMenu, &_showMobileMenu.Header_isScrolling {
        background-color: $primary70;
    }

    &-LogoWrapper {
        flex-shrink: 0;
        display: inline-block;
        height: var(--header-logo-height);
        width: var(--header-logo-width);

        &_showMobileMenu {
            filter: contrast(100) invert(1);
        }
    }

    &-ExtraButton {
        font-family: "filson-soft", sans-serif;
        padding: 10px 20px;
        margin-right: 36px;
        background-color: $primary50;
        border-radius: 100px;
        display: inline-block;
        color: $neutral5;
        font-weight: 400;
        font-size: 15px;
        transition: background-color .3s ease-in-out;
        white-space: nowrap;

        &:hover, &:focus {
            background-color: $primary90;
            text-decoration: none;
            color: $neutral5;
        }

        &_showMobileMenu {
            background-color: $neutral5;
            color: $primary90;
            &:hover{
                color: $neutral5;
            }
        }
    }

    &-MobileRightWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &-Menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-MobileMenuWrapper {
        display: flex;
        position: fixed;
        top: var(--header-height);
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100vh - var(--header-height));
        padding: 55px 23px 55px 50px;
        background-color: $primary50;
        z-index: 99;
    }

    &_isScrolling {
        background: $primary5;
    }

    &-MyAccountWrapper {
        display: flex;
        align-items: center;
    }

    &-MyAccountWrapper,
    &-MinicartButtonWrapper,
    .SearchField {
        @include desktop {
            margin-right: 32px;
        }

        @include tablet {
            margin-right: 26px;
        }

        @include mobile {
            margin-right: 18px;
        }
    }

    &-Welcome {
        font-family: "filson-soft", sans-serif;
        font-size: 12px;
        font-weight: 600;
    }
}

@include tablet {
    :root {
        --header-height: 77px;
        --header-nav-height: 77px;
        --header-total-height: 77px;
        --header-logo-width: 101px;
        --header-logo-height: 40px;
    }

    .Header {
        padding: 0 31px 0 24px;

        &-ExtraButton{
            margin-right: 30px;
            transition: none;
        }
    }
}

@include mobile {
    :root {
        --header-height: 67px;
        --header-nav-height: 67px;
        --header-total-height: 67px;
        --header-logo-width: 73px;
        --header-logo-height: 30px;
    }

    .Header {
        padding: 0 19px 0 23px;

        &-LogoWrapper {
            width: 100px;
        }

        &-ExtraButton {
            padding: 9px 15px;
            font-size: 13px;
            margin-right: 20px;
            text-align: center;
        }
    }
}
