/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Instagram {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 1270px;
    margin: 0 auto 165px auto;
    padding-top: 96px;
    border-top: solid 1px $primary10;

    &-Photos{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 33px;
        align-items: end;
        margin-bottom: 36px;
    }

    &-Photo{
        max-width: 185px;
        transition: transform .3s ease-in-out;
        &:hover{
            transform: translateY(-5px);
        }
    }

    &-Link{
        color: $primary50;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        transition: color .3s ease-in-out;
    }
}

@include tablet{
    .Instagram {
        margin: 50px auto 60px auto;
        padding-top: 50px;

        &-Photos{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@include mobile{
    .Instagram {
        margin: 25px auto 30px auto;
        padding-top: 25px;

        &-Photos{
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 15px;
        }
    }
}
