.CopyrightCMS {
    height: 57px;
    margin-top: 80px;
    box-sizing: border-box;
    border-top: 1px solid $primary10;
    p {
        width: 272px;
        color: $neutral50;
        margin-top: -11px;
        background: $neutral5;
    }
}

@include tablet {
    .CopyrightCMS {
        margin-top: 70px;
        p {
            width: 300px;
            padding-left: 25px;
        }
    }
}

@include mobile {
    .CopyrightCMS {
        margin-top: 45px;
        margin-bottom: 30px;
        p {
            padding-left: 25px;
        }
    }
}
