/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.VideoPlayer {
    &-Wrapper {
        overflow: hidden;
        position: relative;
        padding-top: 56.25%;
        width: 100%;
    }

    &-Player {
        position: absolute;
        inset: 0;
    }

    .react-player__preview {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            background: rgba(150, 191, 219, .7);
        }

        svg {
            width: 55px;
            height: 55px;

            path {
                fill: $neutral10;
            }
        }
    }
}
