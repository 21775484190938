.OurValuesCMS {
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 25px;

    &-Heading {
        text-align: center;
        max-width: 850px;
        margin: 100px auto;
    }

    &-Content {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }

    &-Images {
        width: 480px;
        display: flex;
        flex-direction: column;

        &-Img {
            display: flex;
            &:nth-of-type(2) {
                margin-top: -103px;
                justify-content: flex-end;
            }

            &:nth-of-type(3) {
                margin-top: -159px;
                padding-left: 65px;
            }

            img {
                width: auto;
                height: auto;
            }
        }
    }
}

@include tablet {
    .OurValuesCMS {
        min-height: 600px;

        &-Images {
            &-Img {
                &:nth-of-type(2) {
                    width: 200px;
                    margin-top: -30px;
                    margin-left: 10%;
                }

                &:nth-of-type(3) {
                    width: 160px;
                    margin-top: -50px;
                    padding-left: 0;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@include mobile {
    .OurValuesCMS {
        min-height: unset;
        padding: 0 10px;

        padding-bottom: 50px;
        &-Content {
            justify-content: center;
        }

        &-Images {
            display: none;
        }
    }
}
