/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ReportsNewsletter {
    padding: 50px 20px;
    background-color: #9DC5DF;
    display: flex;

    .ButtonContainer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .Button {
        color: #9DC5DF;
        font-weight: 600;
        width: 250px
    }

    .Field {
        margin-top: 34px;
        &-Label {
            margin-bottom: 5px;
            &_isRequired {
                &::after{
                    content: '*';
                    color: var(--color-white);
                }
            }
        }
        &_type_checkbox {
            margin-top: 10px;
        }
    }

    .Form {
        width: 100%;
    }

    .FieldForm {
        input {
            background-color: #F3F8FB;
        }
        &-Fieldset {
            max-width: 550px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
        }
        &-Agreement {
            margin-top: 10px;
        }
    }

    .TypographyHeader {
        &_heading_h2 {
            font-size: 40px;
            font-weight: 500;
        }
        &_heading_h3 {
            font-size: 25px;
            font-weight: 400;
        }
    }

    .TypographyParagraph {
        color: var(--color-white);
    }
}
