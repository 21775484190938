/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    min-height: 100vh;
    &_isBreadcrumbsHidden {
        margin-top: calc(var(--header-total-height) + 1.2rem);

        @include mobile {
            margin-top: calc(var(--header-total-height) + 1.4rem);
        }
    }

    &-Content {
        ul,
        ol {
            margin-top: 1rem;
        }
    }

    &-Wrapper {
        overflow: hidden;
        overflow: clip;
        grid-template-columns: auto;

        margin: {
            left: auto;
            right: auto;
        };

        &_page_width {
            &_full {
                max-width: 100%;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }

    &-Heading {
        margin-bottom: 1.8rem;

        @include mobile {
            margin-bottom: 2.1rem;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-bottom: 30%;

        @include mobile {
            height: 100vh;
        }

        &:first-child {
            padding-bottom: 40%;
        }
    }

    .TextPlaceholder {
        margin-bottom: 2.16rem;
        display: block;

        @include mobile {
            margin-bottom: 2.52rem;
        }
    }

    .ContentTabs{
        margin: 100px auto 50px;
    }
}
