[class*="HpTabs"] {
    .LpPrimaryProducts .LpSecondaryProducts {
        margin-block: initial;
    }

    .ContentTabs-Box {
        &:hover {
            @include desktop {
                box-shadow: 0px 40px 80px 0px rgba($primary50, 0.2);
            }
        }

        &-Image {
            margin-bottom: 16px;
        }
    }
}

.HpTabsCategories {
  .slick-list {
    margin: 0 -16px;
  }

  &-Inner {
    margin-bottom: 50px;
  }

  &-Box  {
    padding: 0 16px;
  }

  &-Image {
    display: block;
    height: 268px;
    padding: 16px 0px;
    border-radius: 25px;

    img {
      display: block;
      height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  &-Content {
    .TypographyHeader {
      margin-bottom: 18px;
      color: $neutral80;
    }
  }
}
