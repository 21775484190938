:root {
    --lp-secondary-product-mobile-border-color: #C3E1D0;
}

.LpSecondaryProducts {
    z-index: 3;

    @include container;

    @include tablet {
        padding-inline: 0;
    }

    @include mobile {
        padding-inline: 0;
    }

    @include desktop {
        margin: calc(var(--lp-primary-hero-desktop-padding-bottom, 0) * -1) auto 0;
    }

    .AdvoxSlider {
        .slick-list {
            $productSliderOverlayTopLeft: 0 -30px;
            $productSliderOverlayTopRight: 100% -30px;
            $productSliderOverlayBottomRight: 100% calc(100% + 500px);
            $productSliderOverlayBottomLeft: 0 calc(100% + 500px);

            overflow: initial;
            clip-path: polygon(#{$productSliderOverlayTopLeft}, #{$productSliderOverlayTopRight}, #{$productSliderOverlayBottomRight}, #{$productSliderOverlayBottomLeft});
        }
    }

    & > [data-lp-products] {
        display: grid;
        grid-template-columns: 1fr;

        @include before-mobile {
            grid-template-columns: repeat(2,1fr);
        }

        @include desktop {
            grid-template-columns: repeat(4,1fr);
            gap: 24px;
        }
    }

    .ContentTabs-Box {
        min-width: initial;
        padding: 24px;
        border-radius: 0;
        transition: all 250ms ease;

        @include tablet {
            padding: 24px 24px 48px;

            &:not(:first-child):not(:nth-child(2)) {
                border-top: 1px solid var(--lp-secondary-product-mobile-border-color);
            }
        }

        @include mobile {
            &:not(:first-child) {
                border-top: 1px solid var(--lp-secondary-product-mobile-border-color);
            }
        }

        @include desktop {
            border-radius: 24px;

            &:hover {
                background-color: $white;
                box-shadow: 0px 40px 80px 0px #76C09A2E;

                .ContentTabs-Box-Content {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-Image {
            img {
                max-height: 300px;
                display: block;
                max-height: 300px;
                margin: 0 auto;
            }
        }

        &-Content {
            margin: 0 auto;
            padding: 0;

            @include desktop {
                transition: all 250ms ease;
                opacity: 0;
                visibility: hidden;
                box-shadow: 0px 40px 80px 0px #76C09A2E;
                background-color: $white;
                transform: translateY(100%);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                margin: 0 auto;
                padding-top: 0;
                padding-bottom: inherit;
                padding-inline: inherit;
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    transform: translateY(-100%);
                    background-color: inherit;
                    height: 24px;
                    width: 100%;
                }
            }
        }

        .ButtonLanding {
            text-align: center;
            width: 100%;

            &:not(:first-child) {
                margin-top: 24px;
            }
        }

        &-Center {
            padding-top: 55px;
        }
    }

    .TypographyHeader {
        &.TypographyHeader {
            &_heading_h5 {
                margin-block: 0 4px;
                font-size: 13px;
                color: #9595D2;
            }

            &_heading_h4 {
                letter-spacing: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 4px;
                min-height: 63px;
            }
        }
    }

    .TypographyParagraph {
        line-height: 20px;
        text-align: left;

        & + .TypographyParagraph {
            margin-top: 10px;
        }
    }

    .Features {
        margin-top: 20px;

        &_disc_color_advanced {
            li {
                &::before {
                    color: $advanced;
                }
            }
        }

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                display: inline-block;
                content: '\2022';
                margin-left: -20px;
                font-size: 40px;
                line-height: 1px;
                vertical-align: -3px;
            }
        }

        li + li {
            margin-top: 10px;
        }
    }
}
