/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LotAbbreviationsKey {
    &-Wrapper {
        padding: 40px;
        box-shadow: 0 0 15px 0 $neutral30;
        display: grid;
        grid-template-columns: 235px auto;
        grid-gap: 15px;

        @include mobile {
            padding: 30px 20px;
            grid-template-columns: 1fr;
        }
    }

    &-Heading {
        font-size: 24px;
        color: $primary50;
        display: flex;
        align-items: center;
    }

    &-Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
        grid-gap: 15px 35px;

        @include mobile {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        }
    }

    &-Item {
        font-size: 13px;
        display: grid;
        grid-template-columns: minmax(60px, max-content) auto;

        strong {
            white-space: nowrap;
            padding-right: 5px;
        }

        span {
            font-weight: 300;
        }
    }
}
