/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MenuMobile {
    font-family: "filson-soft", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: 100%;
    width: 100%;
    align-items: flex-start;

    &-BackButton {
        color: $primary90;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-List {
        list-style: none;
        width: 100%;
        overflow: auto;
        margin-bottom: 48px;
    }

    &-Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;

        &:not(:last-of-type){
            margin-bottom: 48px;
        }

        &_hasChildren {
            &:not(:last-of-type){
                margin-bottom: 36px;
            }
        }

        &::before{
            display: none;
        }

        svg {
            width: 40px;
            height: 40px;
            padding: 12px;
        }
    }

    &-ItemButton {
        color: #fff;
        font-weight: 700;
        font-size: 21px;
        width: calc(100% - 40px);
        text-align: left;
    }

    &-BackIcon{
        display: inline-block;
        margin-right: 16px;
    }
}
