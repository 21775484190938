/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Consents {
    &-AgreementCMS {
        p {
            color: $neutral5;
            line-height: 1.2;
            a {
                text-decoration: underline;
                color: $neutral5;
            }
        }
        padding-left: 20px;
        margin: 0;

        &_color_black {
            p {
                color: black;
            }
        }

        &_isRequired {
            p {
                &::after {
                    content: '*'
                }
            }
        }
    }
}
