/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Accordion {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
        margin: 0;
    }
    &-Header {
        margin: auto 0;
        transition: color .4s ease-in-out;
        &::after {
            right: 0;
            position: absolute;
            content: url("../../assets/svg/arrow-up.svg");
            transform: rotate(180deg);
        }
    }
    &-Text {
        color: $neutral70;
        p {
            transition: .3s ease-in-out;
            visibility: hidden;
            margin-top: 0;
            line-height: 0;
            opacity: 0;
        }
    }
    &-Box {
        background: $primary5;
        border-radius: 15px;
        padding: 20px 36px;
        text-align: left;
        cursor: pointer;
        &:hover, &_isOpen {
            background: $neutral5;
            box-shadow: 0 40px 80px rgba(82, 149, 200, 0.18);
            .Accordion-Header {
                color: $primary90;
            }
        }
        &_isOpen {
            .Accordion-Text p {
                margin-top: 25px;
                visibility: visible;
                line-height: 1.2;
                opacity: 1;
            }
            .Accordion-Header {
                &:after {
                    transform: rotate(0);
                }
            }
        }
    }
}

@include tablet {
    .Accordion {
        width: 440px;
    }
}

@include mobile {
    .Accordion {
        width: 100%;
        max-width: 550px;
        &-Box {
            background: $primary5;
            padding: 20px 25px 20px 15px;
        }
        &-Header {
            &::after {
                top: calc(50% - 9px);
                right: -15px;
            }
        }
    }
}
