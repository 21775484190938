/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuizPage {
    &:not(&_isNewsletter) {
        .QuizPage-Description {
            @include desktop {
                z-index: 1;
            }

            @include before-desktop {
                position: absolute;
                inset-block-end: 0;
                inset-inline: 0;
                text-align: center;
                background-color: $neutral5;
                border-radius: 25px 25px 0 0;
                padding: 24px;
                max-height: 90px;
                transition: max-height 0.25s ease;

                &Gradient {
                    background: $neutral5;
                    background: linear-gradient(0deg, rgba($neutral5,1) 0%, rgba($neutral5,0) 100%);
                    position: absolute;
                    inset-block-end: 0;
                    inset-inline: 0;
                    width: 100%;
                    height: 100%;
                    transition: background 0.25s ease;
                }

                &:before {
                    position: absolute;
                    content: '';
                    inset-block-start: 0;
                    inset-inline: 0;
                    left: 50%;
                    transform: translateY(-25px) translateX(-50%);
                    width: 400px;
                    height: 64px;
                    background: top no-repeat url('../../assets/svg/white-bottom-dock.svg');
                    background-size: 100%;
                }

                &:after {
                    content: "";
                    inset-block-start: 0;
                    inset-inline-start: 50%;
                    transform: translate(-50%, 0);
                    position: absolute;
                    width: 24px;
                    height: 5px;
                    border-radius: 100px;
                    background: var(--neutral-70, #5E6875);
                }

                &[data-position="up"] {
                    max-height: 100%;

                    .QuizPage-DescriptionGradient {
                        background: transparent;
                    }
                }
            }
        }
    }

    &:not(&_isRecommendation) {
        display: flex;
        flex-direction: column;
        background-color: $primary10;
        background-image: url('../../assets/svg/quiz-background.svg');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 88%;
    }

    @include desktop {
        &:not(&_isRecommendation) {
            min-height: 100vh;
            padding-block: calc(var(--header-height) + 15vh) 20vh;
            display: flex;
            justify-content: center;
        }
    }

    @include before-desktop {
        &:not(&_isRecommendation) {
            z-index: 1000;
        }

        &:not(&_isRecommendation):not(&_isNewsletter) {
            position: fixed;
            inset: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-Top {
        width: 100%;
        height: var(--quiz-header-height, 50px);
        z-index: 1;

        @include before-desktop {
            box-shadow: 0px 40px 80px 0px rgba(82, 149, 200, 0.18);
            border-radius: 0 0 30px 30px;
        }

        @include desktop {
            max-width: 900px;
            margin-inline: auto;
            margin-bottom: -50px;
            border-radius: 30px 30px 0 0;
        }
    }

    &_isNewsletter {
        background-color: $neutral5;

        @include desktop {
            align-items: center;
        }

        .QuizPage-Description {
            padding: 0 24px 24px;
            text-align: center;

            @include desktop {
                margin-top: 24px;
            }
        }

        .QuizPage-Top {
           @include desktop {
               display: none;
           }
        }
    }

    &_isRecommendation {
        .QuizPage-Top {
            margin-top: var(--header-height);
        }
    }

    &_isRecommendation,
    &_isNewsletter {
        background-color: $neutral5;
    }

    &-Header {
        width: 100%;
        height: var(--quiz-header-height, 50px);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $neutral5;
        border-radius: 30px 30px 0 0;

        @include before-desktop {
            border-radius: 0 0 30px 30px;
        }

        &:before {
            position: absolute;
            content: '';
            top: -19px;
            width: 100%;
            height: 64px;
            background: top no-repeat url('../../assets/svg/white-dock.svg');

            @include before-desktop {
                transform: rotate(180deg);
                top: 19px;
            }
        }
    }

    &-NavigationButton {
        display: flex;
        align-items: center;
        margin-left: 20px;
        gap: 10px;
        cursor: pointer;

        &-Text {
            font-family: $font-filson-soft;
            font-size: 15px;
            letter-spacing: 0;
            color: $neutral70;
        }
    }

    &-CloseButton {
        margin-right: 6px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Progress {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include before-desktop {
            top: 16px;
        }

        .ProgressText {
            position: absolute;
            display: flex;
            align-items: baseline;
            color: $neutral70;
            font-weight: 700;
            .ActiveQuestion {
                font-size: 15px;
            }
            .AmountOfQuestions {
                font-size: 10px;
            }
        }
        .Tick {
            position: absolute;
            background: center no-repeat url('../../assets/svg/tick.svg');
            height: 100%;
            width: 100%;
        }
    }

    &-Button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        color: $primary90;
        border: 1px solid $primary20;
        background-color: $neutral5;
        padding: 8px 16px;
        min-height: 56px;
        max-width: 312px;
        width: 100%;
        text-align: center;
        font-family: $font-filson-soft;
        font-size: 15px;
        line-height: 150%;
        cursor: pointer;

        @include before-desktop {
            max-width: initial;
            width: 100%;
        }

        span {
            &:first-letter {
                text-transform: uppercase;
            }
        }

        &_isAnswer {
            // styles applied to answer buttons only
            flex-direction: column;

            span:not(:first-of-type) {
                font-size: 13px;
                line-height: 16px;
                color: $neutral50;
                text-transform: none;
            }
        }

        &_isSubmitButton {
            margin-top: 16px;
            background-color: $primary90;
            color: $neutral5;
        }

        &_isSkipNewsletterButton {
            margin-top: 16px;
            background: $primary5;
        }

        &_isAnswerChecked {
            background-color: $primary50;
            color: $neutral5;
        }

        &_isAddToCartButton {
            background-color: $primary50;
            color: $neutral5;
            margin-bottom: 8px;
        }
    }

    &-Description {
        * {
            font-weight: 350;
            line-height: 20px;
            color: $neutral70;
        }
    }
}
