.LandingPage {
    --lp-primary-hero-background: transparent;
    --lp-primary-hero-desktop-default-padding-bottom: 46px;
    --lp-primary-hero-desktop-padding-bottom: 0px;

    overflow: hidden;

    .LpPrimaryFootnote {
        margin-top: 32px;

        .LandingWrapper {
            @include container;
        }
    }

    &_eko {
        .LpPrimaryDescription {
            margin-block: 0 40px;

           @include desktop {
               margin-block: 130px 77px;
           }
        }

        .LpPrimaryDescription-ImageSmall {
            margin-left: -14px;
            margin-right: -14px;
        }
    }

    &_nature {
        --lp-primary-hero-background: #eff7f2;
        --lp-primary-hero-desktop-default-padding-bottom: 46px;
        --lp-primary-hero-desktop-padding-bottom: 231px;
    }

    &_advanced {
        @include tablet {
            --lp-primary-hero-background: #F3F3FA;

            .LpPrimaryHero {
                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 225px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transform: translateY(100%);
                    background-image: url("../../../../assets/svg/line-advanced_bg.svg");
                    background-position: top;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }

                .TypographyHeader, .LpPrimaryHero-Content .text .Hero-Paragraph {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &_marine {
        --lp-primary-hero-background: #{$marine25};

        @include tablet {
            --lp-primary-hero-background: #{$advanced25};
        }

        .LpPrimaryHero {
            padding-bottom: 40px;

            @include desktop {
                padding-bottom: 135px;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 225px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transform: translateY(10px) translateX(40px);
                    background-image: url("../../../../assets/images/marine_hero_decoration.png");
                    background-position: bottom right;
                    background-size: auto;
                    background-repeat: no-repeat;
                }
            }

            .LpPrimaryHero-Logo > * {
                height: 83px;

                @include desktop {
                    height: 126px;
                }
            }

            .TypographyHeader {
                margin-bottom: 16px;
            }
        }

        .LpPrimaryHeadingContainer {
            .TypographyHighlight {
                color: $marine;
            }
        }

        .LpMarineProducts {
            .ImagesWithDescCMS {
                @include container;

                margin-block: 40px;

                @include desktop {
                    margin-block: 95px 104px
                }

                .TypographyHeader {
                    margin: 0 0 16px;
                }

                &-Block {
                    @include desktop {
                        grid-template-columns: 1fr 1fr;
                        padding: 32px 84px;
                        margin-bottom: 0;

                        &:nth-child(even) {
                            .ImagesWithDescCMS-Image {
                                text-align: right;
                            }
                        }
                    }

                    @include tablet {
                        max-width: 100%;

                        &:not(:last-of-type) {
                            margin-bottom: 64px;
                        }

                        .ImagesWithDescCMS-Image {
                            text-align: center;
                        }
                    }
                }

                &-Image {
                    @include tablet {
                        margin-bottom: 16px;
                        order: -1;
                    }
                }

                &-Content {
                    padding: 0;
                }

                &-Text {
                    color: $black;
                }

                &-ButtonWrap {
                    margin-top: 32px;

                    @include tablet {
                        .ButtonLanding {
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .LpMarineRawMaterials {
            &::after {
                @include desktop {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: translateY(-50%);
                    height: 847px;
                    width: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left bottom;
                    background-image: url("../../../../assets/svg/lp_marine_goed_bg.svg");
                    z-index: -1;
                }
            }

            .ImagesWithDescCMS {
                @include container;

                margin-block: 0 64px;

                @include desktop {
                    margin-block: 0 104px
                }

                .TypographyHeader {
                    margin: 0 0 16px;
                }

                &-Block {
                    @include desktop {
                        grid-template-columns: 1fr 0.75fr;
                        grid-gap: 100px;
                        padding: 32px 84px;
                        margin-bottom: 0;

                        &:nth-child(even) {
                            .ImagesWithDescCMS-Image {
                                text-align: right;
                            }
                        }
                    }

                    @include tablet {
                        max-width: 100%;

                        &:not(:last-of-type) {
                            margin-bottom: 64px;
                        }

                        .ImagesWithDescCMS-Image {
                            text-align: center;
                        }
                    }
                }

                &-Content {
                    padding: 0;

                    .TypographyHeader {
                        @include tablet {
                            max-width: 100%;
                            text-align: center;
                        }
                    }
                }

                &-Image {
                    @include tablet {
                        margin-bottom: 24px;
                        order: -1;
                    }
                }
            }
        }

        .LpMarineProduce {
            @include container;

            .OfferCMS {
                background-color: $marine25;
                border-radius: 30px;

                &:not(:last-of-type) {
                    margin-bottom: 24px;

                    @include desktop {
                        margin-bottom: 32px;
                    }
                }

                &-Wrapper {
                    display: grid;
                    grid-template-columns: 100%;
                    grid-gap: 24px;
                    max-width: 100%;
                    padding: 32px 24px;
                    align-items: center;

                    @include desktop {
                        padding: 32px 84px;
                        grid-gap: 64px;
                        grid-template-columns: 1fr 1fr;
                    }
                }

                &-ContentWrapper {
                    max-width: initial;
                    margin: 0;
                    padding: 0;

                    &-Content {
                        padding: 0;
                    }

                    .TypographyParagraph {
                        color: $marine90;
                    }
                }

                &-ImageWrapper {
                    display: grid;
                    grid-template-columns: 100%;
                    grid-gap: 24px;
                    width: initial;
                    align-items: center;

                    @include desktop {
                        grid-template-columns: 168px auto;
                    }

                    .TypographyHeader {
                        margin: 0;
                        letter-spacing: inherit;
                        color: $marine70;
                    }

                    &-Graphics {
                        max-width: initial;
                        width: 100%;
                        padding-block: 22px;
                        text-align: center;

                        @include desktop {
                            text-align: right;
                        }

                        &-Img {
                            max-height: 100px;
                            height: 100%;
                            width: auto;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .LpSecondaryBlog {
            margin-block: 100px 65px;

            @include desktop {
                margin-block: 150px;
            }
        }

        .LpMarineKids {
            &-Products {
                text-align: center;
                margin-top: -250px;
                padding-bottom: 122px;

                img {
                    max-height: 500px;
                    height: auto;
                    width: auto;
                    max-width: 100%;
                }
            }

            .OfferCMS {
                background-color: $marine25;
                background-image: url("../../../../assets/svg/lp_marine_kids_bg_mobile.svg");
                background-repeat: no-repeat;
                background-position: right bottom;

                @include desktop {
                    background-image: url("../../../../assets/svg/lp_marine_kids_bg_desktop.svg");
                }

                padding-block: 48px;

                @include desktop {
                    padding-block: 77px 72px;
                }
            }

            .ImagesWithDescCMS {
                margin-block: 0;
                z-index: 1;

                @include container;

                @include tablet {
                    padding-bottom: 162px;
                }

                &-Block {
                    margin-top: 0;
                    grid-gap: 40px;

                    @include tablet {
                        max-width: 100%;
                    }

                    @include desktop {
                        grid-gap: 85px;
                    }
                }

                &-Image {
                    text-align: center;

                    @include desktop {
                        text-align: right;
                    }
                }

                &-Content {
                    padding: 0;

                    img {
                        max-height: 72px;
                        max-width: 100%;
                        width: auto;
                        margin-bottom: 24px;

                        @include desktop {
                            max-height: 109px;
                            margin-bottom: 40px;
                        }
                    }
                }
            }
        }
    }

    &_vital {
        .LpPrimaryCertificate {
            margin-bottom: 32px;
            margin-top: 64px;

            @include desktop {
                margin-top: 147px;
            }
        }

        .LpPrimaryVariousForms {
            .LpPrimaryWrapper {
                @include container;
            }
        }
    }
}
