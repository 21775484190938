.LpPrimaryCertificate {
    @include container;

    padding-inline: 24px;

    &_nature {
        .ButtonLanding, a {
            --button-bg: #{$nature50};
            --link-color: #{$nature50};
        }

        .LpPrimaryCertificate-Wrapper {
            background: $nature25;

            @include desktop {
                background: url('../../../../assets/images/lp_nature_lab_background.png') 100% 50%/cover no-repeat;
            }
        }
    }

    &_advanced {
        .ButtonLanding, a {
            --button-bg: #{$advanced};
            --link-color: #{$advanced};
        }

        .LpPrimaryCertificate-Wrapper {
            background: $advanced25;

            @include desktop {
                background: url('../../../../assets/images/lp_nature_lab_background.png') 100% 50%/cover no-repeat;
            }
        }
    }

    &_marine {
        .ButtonLanding, a {
            --button-bg: #{$marine};
            --link-color: #{$marine};
        }

        .LpPrimaryCertificate-Wrapper {
            background: $marine25;

            @include desktop {
                background: url('../../../../assets/images/lp_nature_lab_background.png') 100% 50%/cover no-repeat;
            }
        }
    }

    &-Wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 409px;
        padding: 102px 84px;
        border-radius: 30px;
        overflow: hidden;

        @include desktop {
            background: url('../../../../assets/images/check_certificate.jpg') 100% 50%/cover no-repeat;
        }

        @include tablet {
            flex-direction: column;
            padding: 0;
            background: $primary5;

            &:before {
                display: none;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(256, 256, 256, 0.4);

            @include tablet {
                display: none;
            }

            @include desktop {
                display: block;
            }

            @media (min-width: 1360px) {
                display: none;
            }
        }

    }

    &-Content {
        @include desktop {
            max-width: 50%;
        }

        @include tablet {
            padding: 40px 24px 24px;
        }

        .TypographyHeader {
            margin-block: 0 24px;
        }

        a:not(.ButtonLanding) {
            font-size: inherit;
            font-weight: inherit;
        }

        &__Button {
            margin-top: 24px;

            .ButtonLanding {
                text-align: center;

                @include tablet {
                    width: 100%;
                }
            }
        }
    }

    &-Image {
        display: none;
        position: relative;

        @include tablet {
            display: block;
            width: 100%;
            order: -1;
        }

        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 90px;
            background: -moz-linear-gradient(top, rgba(243, 248, 251, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(243, 248, 251, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(243, 248, 251, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f8fb', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */

            @include tablet {
                display: none;
            }
        }
    }

    &_girl_bg {
        .LpPrimaryCertificate-Wrapper {
            background: url('../../../../assets/images/check_certificate_girl.jpg') 100% 50%/cover no-repeat;

            @include mobile {
                background: $primary5;
            }
        }
    }

    &_bg_color_vital_secondary {
        .LpPrimaryCertificate-Wrapper {
            @include mobile {
                background: $vital_secondary;
            }
        }

        .LpPrimaryCertificate-Image {
            &:before {
                background: -moz-linear-gradient(top, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f8fb', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
            }
        }
    }
}


.tflex-wrap {
    width: 200px;
}

.tflex {
    display: flex;
}
