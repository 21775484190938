/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogRecentPosts_isOverridden {
    margin-top: 50px;

    .BlogRecentPosts {
        &-Wrapper {
            padding: 20px;

            @include narrow-desktop {
                padding: 10px;
                flex-basis: 70%;
            }

            &_isNoImage {
                flex-basis: 100%;
            }
        }

        &-Title {
            font-size: 20px;
            text-align: left;
            text-transform: unset;
            font-weight: 500;
            margin-left: 30px;
            margin-bottom: 20px;
        }

        &-PostCard {
            border: none;
            background-color: $primary5;
            margin-bottom: 20px;
            padding: 0;

            &:last-child {
                border: none;
            }
        }

        &-ImageWrapper {
            padding: 20px;

            @include narrow-desktop {
                padding: 10px;
                flex-basis: 30%;
            }

            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }
        }

        &-PostTitle {
            font-weight: 300;
            margin-bottom: 10px;
            text-transform: none;

            @include narrow-desktop {
                font-size: 16px;
            }
        }

        &-Date {
            position: static;
            color: $primary50;
            border-top: solid 1px $neutral30;
            padding-top: 10px;
            font-size: 14px;
            font-weight: 400;
        }
    }
}
