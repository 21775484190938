*{
    vertical-align: bottom;
}

body {
    font-family: $font-filson-pro;
}

p{
    margin-bottom: 0;
}

sup {
    vertical-align: super;
}

iframe[style*="z-index: 2147483647;"] {
    display: none;
}

html {
    scroll-behavior:smooth;
}
