@include narrow-desktop {
    .homepage-slider {
        div > .SliderWidget-Figure {
            img {
                object-position: 30% 50%;
            }
        }
    }
}

@include tablet {
    .homepage-slider {
        div > .SliderWidget-Figure {
            img {
                object-position: 30% 50%;
            }
        }
    }
}

@include mobile {
    .homepage-slider {
        .SliderWidget-Figcaption {
            padding-inline: 45px;
        }

        div > .SliderWidget-Figure {
            .SliderWidget-Figcaption {
                .TypographyHighlight, .TypographyParagraph {
                    color: black;
                }
            }

            img {
                opacity: 0.7;
                object-position: 15% 50%;
            }
        }
    }
}
