.LpEcoGoed {
    margin-bottom: 80px;

    .LpPrimaryWrapper {
        @include container;
    }

    .TypographyHeader_heading_h2 {
        margin: 0 0 8px;
    }

    .LpEco-SubTitle {
        margin: 0 auto 20px;
        max-width: 700px;
        color: $black;
        font-size: 18px;
        font-weight: 350;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;

        @include desktop {
            margin-bottom: 46px;
        }
    }

    &-Grid {
        padding: 40px 20px;
        border-radius: 30px;
        background-color: $primary50;

        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        grid-gap: 33px;
        font-weight: 350;
        font-family: "filson-soft", sans-serif;

        @include desktop {
            padding: 64px 84px;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            gap: 64px;
            max-width: 1380px;
            margin: 0 auto;
        }
    }

    &-Image {
        position: relative;

        @include desktop {
            order: 0;
        }

        @include tablet {
            margin: 0 auto;
            max-width: 193px;
        }

        &:before {
            display: none;
            content: '';
            width: 136px;
            height: 63px;
            position: absolute;
            right: -85px;
            top: 0px;
            z-index: 2;
            background: url('../../../../assets/images/lp_eco_goed_right.svg') 0 0 no-repeat;
            @include desktop {
                display: block;
            }
        }
    }

    &-Text {
        color: $white;

        &_1 {
            font-size: 21px;
            line-height: 140%;

            @include tablet {
                font-size: 18px;
            }
        }

        &_2 {
            font-size: 15px;
        }
    }
}
