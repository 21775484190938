/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuizNewsletter {
    @include before-desktop {
        margin-top: calc(var(--quiz-header-height, 50px) * -1);
    }

    &-ContentContainer {
        border-radius: 0 0 30px 30px;

        @include desktop {
            width: 100%;
            background: $primary5;
        }

        @include before-desktop {
            background-color: $neutral5;
            padding: 35% 24px 0;
        }
    }

    &-TitleAndContent {
        @include desktop {
            padding: 48px;
            flex: 1 1 50%;
            max-width: 50%;
        }
    }

    &-Form {
        @include desktop {
            padding: 71px;
            border-radius: 25px;
            background-color: $neutral5;
            box-shadow: 0px 40px 80px 0px rgba(82, 149, 200, 0.18);
            flex: 1 1 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Title {
        color: $neutral80;
        text-align: center;
        font-family: $font-filson-soft;
        font-size: 30px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.6px;
        margin: 0;
        text-transform: none;


        @include desktop {
            text-align: left;
            margin-bottom: 24px;
        }
    }

    &-Content {
        p, ul, li, span {
            color: $neutral70;
            text-align: center;
            font-family: $font-filson-soft;
            font-size: 15px;
            font-weight: 350;
            line-height: 150%;

            @include desktop {
                text-align: left;
            }
        }

        > * {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        strong{
            font-weight: 500;
        }
    }

    &-SkipNewsletterButtonContainer {
        display: flex;
        justify-content: center;
        padding-bottom: 32px;
    }

    &-ContentSeparator {
        margin-block: 32px 28px;
        border-bottom: 1px solid #CFE2EF;
    }

    &-ForYou {
        @include before-desktop {
            padding: 32px 24px;
        }

        h3 {
            font-size: 15px;
            font-weight: 350;
            line-height: 22px;
            text-align: left;
            color: $neutral80;
            margin-block: 0 30px;
        }

        &Item {
            display: flex;
            gap: 32px;

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }

        &Label {
            position: absolute;
            left: -68px;
            height: 40px;
            width: 40px;
            background: white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            color: $neutral50;

            @include before-desktop {
                display: none;
            }
        }

        &Description {
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;

            strong, b {
                font-weight: 600;
            }
        }
    }
}
