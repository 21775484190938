/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --contact-form-select-border-radius: 28px;
    --contact-form-input-error-color: #{$error50};
    --contact-form-input-valid-color: #{$advanced50}
}

.ContactForm {
    .Field {
        &_hasError {
            .FieldSelect {
                &-Select {
                    background: $error5;
                    color: $error70;
                    border-color: var(--contact-form-input-error-color);
                }

                .ChevronIcon {
                    fill: var(--contact-form-input-error-color);
                }
            }
        }

        &_isValid {
            .FieldSelect {
                &-Select {
                    color: $advanced70;
                    background: $advanced5;
                    border-color: var(--contact-form-input-valid-color);
                }
            }
        }

        .FieldSelect {
            &-Select {
                &_isExpanded {
                    color: $advanced70;
                    border-color: var(--contact-form-input-valid-color);
                    border-bottom-width: 0;
                    border-radius: var(--contact-form-select-border-radius) var(--contact-form-select-border-radius) 0 0;
                }
            }

            &-Options {
                &_isExpanded {
                    border-color: var(--contact-form-input-valid-color);
                    border-top-width: 0;
                    border-radius: 0 0 var(--contact-form-select-border-radius) var(--contact-form-select-border-radius);
                }
            }

            &-Option {
                color: $advanced70;
                background: $primary5;
                padding: 2px 32px;
                max-height: 42px;

                &:hover {
                    background: $advanced5;
                }
            }

            .ChevronIcon {
                fill: var(--contact-form-input-valid-color);
            }
        }
    }
}
