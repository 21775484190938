/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuizQuestion {
    height: 100%;

    @include desktop {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Content {
        @include desktop {
            display: flex;
            max-width: 900px;
            width: 100%;
            min-height: 525px;
            margin: auto;
            background-color: $neutral5;
            border-radius: 25px;
            box-shadow: 0px 40px 80px 0px rgba(82, 149, 200, 0.18);

            &.QuizNewsletter-ContentContainer {
                width: 100%;
            }

            .QuizPage-Description {
                flex: 1 1 30%;
                max-width: 30%;
                background: #F3F8FB;
                border-radius: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 45px 24px 45px 45px ;
            }
        }

        @include before-desktop {
            display: grid;
            justify-items: center;
            height: 100%;
            gap: 24px;
        }
    }

    &-QuestionAndAnswers {
        @include before-desktop {
            width: 100%;
            padding: 35% 24px;
            overflow: auto;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 1 70%;
            max-width: 70%;
            padding: 92px 45px;
        }
    }

    &-Question {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral80;
        font-family: $font-filson-soft;
        font-size: 20px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.4px;
        text-transform: none;
        margin: 0;

        @include desktop {
            font-size: 30px;
            font-weight: 500;
            line-height: 45px;
        }
    }

    &-Answers {
        margin-top: 45px;
        display: grid;
        justify-items: center;
        width: 100%;
        gap: 16px;
    }
}
