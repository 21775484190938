.LpPrimaryProductAlternately {
    img {
        max-width: 100%;
        width: auto;
    }

    &-Grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;
        margin-bottom: 24px;
        padding: 32px 20px;
        background-color: $primary5;
        border-radius: 30px;

        .ButtonLanding {
            @include tablet {
                text-align: center;
                width: 100%;
            }
        }

        .TypographyHeader {
            margin-block: 0 16px;
        }

        .TypographyParagraph {
            &_highlight {
                color: $primary90;
                font-weight: 600;
            }
        }

        @include desktop {
            align-items: center;
            padding: 32px 84px;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-Image {
        @include tablet {
            text-align: center;
        }
    }

    &-Points {
        margin-top: 32px;

        .TypographyParagraph {
            margin-bottom: 8px;
            font-weight: 600;
        }
    }

    &-Content {
        ul {
            margin-bottom: 32px;

            li {
                position: relative;
                list-style: none;
                padding: 0 0 0 24px;
                margin: 0 0 8px;
                font-size: 13px;
                font-weight: 350;
                line-height: 19.5px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    display: block;
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $vital;
                }
            }
        }
    }
}

.CmsPage-Content .LpPrimaryProductAlternately {
    &-Content {
        ul {
            margin-top: 0;
        }
    }
}
