:root {
    --lp-linette-header-background: #E7ECF1;
    --lp-linette-header-span-color: #9EC5DF;
}

.LpPartnership {
    overflow: hidden;

    img {
        object-fit: contain;
        object-position: center;
    }

    &-Button {
        @include button;

        margin-block-start: 0;
    }

    &-Heading, h1, h2 {
        @extend .TypographyHeader;
        @extend .TypographyHeader_heading_h2;

        span {
            color: var(--lp-linette-header-span-color)
        }
    }

    &-Text, p {
        @extend .TypographyParagraph;
        @extend .TypographyParagraph_color_black;
        @extend .TypographyParagraph_size_large;

        & ~ .LpPartnership-Text,
        & ~ p,
        &:first-of-type {
            margin-block-end: 28px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        &_medium {
            font-weight: 600 !important;
        }

        &_light {
            font-weight: 100 !important;
        }

        &_italic {
            font-style: italic;
        }
    }

    &-InstagramFeed {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &-Header {
        padding-block: calc(var(--header-height)) 34px;
        background-color: var(--lp-linette-header-background);
        text-align: left;

        @include desktop {
            padding-block: calc(var(--header-height) + 80px) 34px;
            padding-block-end: 64px;
            text-align: center;
        }

        &::before {
            pointer-events: none;
            content: "";
            position: absolute;
            width: 450px;
            height: 100%;
            max-width: 100%;
            left: 0;
            top: calc(var(--header-height) + 32px);
            background-position: left top;
            background-image: url('../../../../assets/svg/lp_partnership_header_before.svg');
            background-size: auto;
            background-repeat: no-repeat;

            @include tablet {
                width: 100%;
                max-width: 100%;
                background-size: 100%;
                top: calc(var(--header-height));
                background-image: url('../../../../assets/svg/lp_partnership_header_mobile_1.svg');
            }
        }

        &::after {
            pointer-events: none;
            content: "";
            position: absolute;
            width: 450px;
            height: 450px;
            max-width: 100%;
            right: 0;
            bottom: 230px;
            background-position: right bottom;
            background-image: url('../../../../assets/svg/lp_partnership_header_after.svg');
            background-size: auto;
            background-repeat: no-repeat;

            @include tablet {
                display: none;
            }
        }

        &Text {
            @include before-mobile-legacy {
                max-width: 60%;
                margin-inline: auto;
            }

            @include tablet {
                margin-left: 0;
            }
        }

        &ImageOnlyMobile {
            margin-top: 40px;
            margin-inline: -14px;

            @include before-mobile {
                display: none;
            }
        }

        &Content {
            z-index: 0;

            @include container;

            &::before {
                pointer-events: none;
                display: none;
                content: "";
                position: absolute;
                height: 502px;
                width: 315px;
                left: 0;
                top: 0;
                transform: translate(25px, 480px);
                background-position: center;
                background-image: url('../../../../assets/images/lp_partnership_header_2.png');
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;

                @include before-mobile {
                    display: block;
                    height: 1053px;
                    width: 697px;
                    transform: translate(-210px, 350px);
                }
            }

            &::after {
                pointer-events: none;
                content: "";
                display: none;
                position: absolute;
                height: 490px;
                width: 260px;
                right: 0;
                top: 0;
                transform: translate(0, -100px);
                background-position: center;
                background-image: url('../../../../assets/images/lp_partnership_header_1.png');
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;

                @include desktop {
                    display: block;
                    height: 1053px;
                    width: 697px;
                    transform: translate(200px, -100px);
                }
            }
        }
    }

    &-Logo {
        margin-block-end: 40px;

        svg {
            height: auto;
        }

        @include tablet {
            svg {
                max-width: 150px;
                margin-block-start: 40px;
            }
        }

        @include desktop {
            margin-block-end: 48px;
        }
    }

    &-WhyOsavi {
        z-index: 0;
        margin-block: 40px;
        margin-inline: auto;
        margin-right: 0;
        padding: 32px 32px 82px;
        text-align: center;
        background-color: var(--lp-linette-header-background);

        @include before-mobile {
            max-width: 60%;
        }

        @include desktop {
            max-width: 682px;
            margin-block: 80px;
            padding-block: 80px;
            padding-inline: 92px;
            margin-inline: auto;
        }

        &::before, &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 80%;
            z-index: -1;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        &:before {
            top: 0;
            background-position: top right;
            background-image: url('../../../../assets/svg/why-osavi-border-right-top.svg');
            transform: matrix(-1, 0, 0, 1, 0, 0);

            @include desktop {
                background-position: top left;
                background-image: url('../../../../assets/svg/why-osavi-border-left-top.svg');
                transform: initial;
            }
        }

        &::after {
            bottom: 0;
            background-position: bottom left;
            background-image: url('../../../../assets/svg/why-osavi-border-left-bottom.svg');
            transform: matrix(-1, 0, 0, 1, 0, 0);

            @include desktop {
                transform: initial;
                background-position: bottom right;
                background-image: url('../../../../assets/svg/why-osavi-border-right-bottom.svg');
            }
        }
    }

    &-Together {
        &Content {
            @include container;

            z-index: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
            padding-block: 90px 60px;
            max-width: 974px;
            text-align: center;

            @include desktop {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 80px;
                padding-block: 95px 72px;
                text-align: left;
            }

            &::before {
                content: "";
                position: absolute;
                height: 713px;
                width: 571px;
                left: 0;
                top: 0;
                transform: translate(-100%, 95px);
                background-position: center;
                background-image: url('../../../../assets/svg/together-bg-left.svg');
                background-size: auto;
                background-repeat: no-repeat;
                z-index: -1;
            }

            &::after {
                content: "";
                position: absolute;
                height: 907px;
                width: 907px;
                right: 0;
                top: 0;
                transform: translate(95%, 140px);
                background-position: center;
                background-image: url('../../../../assets/svg/together-bg-right.svg');
                background-size: auto;
                background-repeat: no-repeat;
                z-index: -1;
            }
        }

        &Text {
            @include desktop {
                justify-self: right;
                padding-block-start: 50px;
            }
        }

        &Image {
            max-height: 502px;
            width: 100%;
            order: -1;
            text-align: center;

            @include desktop {
                order: initial;
            }

            @include desktop {
                justify-self: left;
            }
        }

        &-Button {
            margin-top: 60px;
        }
    }

    &-Exceptional {
        text-align: center;
        padding-block: 60px 90px;

        @include desktop {
            padding-block: 54px 100px;
        }

        &Content {
            @include container;

            max-width: 1070px;
        }

        h2 {
            margin-top: 0;
            margin-block-end: 64px;

            @include tablet {
                margin-bottom: 36px;
            }
        }

        .LpPartnership-Text {
            font-size: 23px;
            line-height: 33px;
        }

        &Background {
            position: absolute;
            height: 100%;
            width: 100%;
            max-width: 529px;
            margin-inline: auto;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;

            img {
                position: absolute;
                width: auto;

                &:first-of-type {
                    left: 0;
                    top: 0;
                    transform: translate(0, -125%);

                    @include before-mobile-legacy {
                        transform: translate(0, -125%);
                    }

                    @include desktop {
                        transform: translate(-115%, 100%);
                    }
                }

                &:last-of-type {
                    bottom: 0;
                    right: 0;
                    transform: translate(0, 140%);

                    @include before-mobile-legacy {
                        transform: translate(10%, 140%);
                    }

                    @include desktop {
                        top: 0;
                        transform: translate(115%, 350%);
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                height: 70px;
                width: 131px;
                left: 0;
                top: 0;
                transform: translate(-10%, 30%) rotate(45deg);
                background-position: center;
                background-image: url('../../../../assets/svg/exceptional-arrow-left.svg');
                background-size: auto;
                background-repeat: no-repeat;

                @include desktop {
                    transform: translate(-50%, 230%);
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 70px;
                width: 131px;
                right: 0;
                bottom: 0;
                transform: translate(20%, -40%) rotate(46deg);
                background-position: center;
                background-image: url('../../../../assets/svg/exceptional-arrow-right.svg');
                background-size: auto;
                background-repeat: no-repeat;

                @include desktop {
                    top: 0;
                    transform: translate(65%, 150%);
                }
            }
        }

        &Image {
            max-height: 462px;
            max-width: 562px;
            margin-inline: auto;
            margin-block: 145px;

            @include desktop {
                margin-block: 55px;
            }

            @include aspect-ratio(100%, "& > img");

            & > img {
                z-index: 1;
            }
        }

        p {
            &:first-of-type {
                @include tablet {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            &:last-of-type {
                margin-block: 35px;
            }
        }
    }

    &-Recipes {
        background-color: rgba(#D9D9D9, 0.16);
        padding-block: 74px 69px;

        @include desktop {
            padding-block: 75px 95px;
        }

        &Content {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;

            @include container;

            @include desktop {
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                grid-gap: 130px;
            }
        }

        h2 {
            text-align: center;
            margin-block-end: 37px;

            @include desktop {
                margin-block-end: 64px;
            }
        }

        .LpPartnership-Button {
            margin-top: 60px;
        }

        &Text {
            @include desktop {
                justify-self: left;
            }
        }

        &Image {
            max-height: 392px;
            width: 100%;

            @include desktop {
                justify-self: right;
            }

            img {
                object-fit: cover;
            }

            @include aspect-ratio(56.54%);
        }
    }

    &-Harmony {
        &Content {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
            padding-block: 94px;

            @include desktop {
                grid-template-columns: 1fr 2fr;
                align-items: center;
                grid-gap: 80px;
            }

            @include container;

            @include tablet {
                text-align: center;
            }
        }

        &Text {
            &:not(&_mobile) {
                @include tablet {
                    display: none;
                }
            }

            &_mobile {
                @include before-mobile-legacy {
                    display: none;
                }
            }
        }

        h2 {
            margin: 0;
            text-align: center;

            @include desktop {
                text-align: left;
            }
        }
    }

    &-Adventure {
        @include container;

        text-align: center;
        border-top: 1px solid #ADCEE4;
        padding-block-start: 90px;

        @include desktop {
            padding-block-start: 100px;
        }

        h2 {
            margin: 0;

            & + * {
                margin-top: 8px;
            }
        }

        &Content {
            &::before {
                pointer-events: none;
                content: "";
                position: absolute;
                height: 285px;
                width: 285px;
                left: 0;
                top: 0;
                transform: translate(-50%, -50%);
                background-position: center;
                background-image: url('../../../../assets/svg/lp_adventure_bg.svg');
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;

                @include tablet {
                    transform: translate(-50%,-75%);
                }
            }

            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-gap: 24px;
            margin-top: 60px;

            @include desktop {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                align-items: center;
            }
        }
    }

    &-Create {
        @include container;

        &Text {
            svg {
                margin-bottom: 32px;
            }
        }

        &Sign {
            @include desktop {
                position: absolute;
                right: 0;
                transform: translateX(-100%);
            }

            @include tablet {
                display: block;
                margin-left: auto;
            }
        }

        &Content {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-gap: 24px;
            margin-top: 60px;

            @include desktop {
                grid-template-columns: repeat(1, minmax(0, 1.5fr)) repeat(1, minmax(0, 1fr));
                align-items: center;
            }
        }
    }

    &-Together,
    &-Create,
    &-Adventure,
    &-Harmony {
        h2 {
            margin-block-end: 32px;
        }

        p + * {
            margin-top: 32px;
        }

        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }
}
