.BannerCMS {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
    max-width: 1272px;
    margin: 0 auto;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #70A3CA;
        z-index: 1;
        opacity: 0.8;
    }

    &_isLarge {
        min-height: 562px;
        .BannerCMS-Img {
            img {
                object-fit: cover;
                height: auto;
            }
        }
    }

    &-Img {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            height: 400px;
            width: auto;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
    }

    &-Title,
    &-Subtitle,
    .Button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &-Title {
        margin-bottom: 38px;
    }

    &-Subtitle {
        margin-bottom: 53px;
    }

    .Button {
        min-width: 200px;
        margin-bottom: 30px;
    }
}

@include tablet {
    .BannerCMS {
        &-Title {
            font-size: 40px;
        }
    }
}

@include mobile {
    .BannerCMS .BannerCMS-Img img {
        height: auto;
    }
    .BannerCMS {
        &-Title {
            font-size: 32px;
            margin-bottom: 26px;
        }
        &-Subtitle {
            font-size: 16px;
            margin-bottom: 53px;
        }
        .Button {
            margin-bottom: 30px;
        }
    }
}
