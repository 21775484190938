/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LotSearch {
    a {
        color: $primary50;
        text-decoration: underline;
    }
    margin: 0 auto;
    .TypographyParagraph {
        font-family: $font-filson-soft;
        font-weight: 300;
    }
    .Tips-Box {
        padding: 10px 0 40px;
        height: 70px;
        font-size: 15px;
        .LotSearch-Error {
            color: $error70;
        }
        .LotSearch-Tooltip {
            text-decoration: underline;
            position: relative;
            cursor: pointer;
            &::after {
                pointer-events: none;
                transition-delay: 250ms;
                transition-duration: 100ms;
                transition-property: opacity;
                background: url("../../assets/images/lot-search-tooltip.png");
                background-position: center;
                background-size: 100%;
                opacity: 0;
                content: '';
                width: 354px;
                height: 360px;
                position: absolute;
                top: -265px;
                left: 125px;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    &-Form {
        margin-top: 30px;
    }
    .LotSearchInfo {
        h4 {
            text-transform: uppercase;
        }
    }
    .Hero {
        background-color: $white;
        padding-bottom: 70px;
        &-Grid {
            display: flex;
            gap: 75px;
            max-width: 1200px;
            padding: 0 24px;

            > * {
                flex: 1 1 0;
            }

            &-SmallImage {
                width: auto;
                height: auto;
            }
        }
        &-Content {
            max-width: 500px;
        }
    }
    &-Logo {
        max-width: 380px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        h2 {
            font-weight: 500;
        }
    }
    &-InputLabel {
        text-transform: uppercase;
        color: $black;
    }
    .SearchField-Input {
        &, &:focus, &:active {
            background-color: $primary10;
            box-shadow: unset;
            padding-left: 20px;
            border-radius: unset;
            height: 45px;
            margin-top: 8px;
        }
    }
    .Button {
        padding: 14px 60px;
        font-size: 14px;
    }
}

@include mobile {
    .LotSearch {
        .Hero-Grid {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }
        .Tips-Box {
            .LotSearch-Tooltip {
                &::after {
                    width: 254px;
                    height: 260px;
                    top: -270px;
                    left: 0;
                }
            }
        }
    }
}




