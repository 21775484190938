/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Player {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;

    video {
        object-fit: cover;
        object-position: center;
    }

    &-Controls {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 16px;
        display: grid;
        grid-template-columns: 24px 24px;
        grid-gap: 8px;
        z-index: 2;
    }

    &-Overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: rgba($primary50, 0.5);
    }
}
