/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AdvoxSlider {
    max-width: 100%;
    margin: 0 auto;

    &_variant_none {
        .slick-slide > div {
            margin: 0;
        }
    }

    &_variant_default {
        .slick-slide > div {
            margin: 0 15px;
        }
    }

    &_variant_lp-products {
        .slick-arrow {
            display: block;
            width: 24px;
            height: 41px;
            position: absolute;
            top: 171px;
            z-index: 10;
            cursor: pointer;
            text-indent: -9999px;
            transition: transform 0.3s ease 0s;


            &.slick-disabled {
                display: none !important;
            }

            &.slick-prev {
                left: 0;
                background: url('../../assets/images/lp_arrow_left.png') 0 0 no-repeat;
                &:hover {
                    transform: translateX(-5px);
                }
            }

            &.slick-next {
                right: 0;
                background: url('../../assets/images/lp_arrow_right.png') 0 0 no-repeat;
                &:hover {
                    transform: translateX(5px);
                }
            }
        }
    }

    &_variant_hp-horizontal-tabs-categories {
        .slick-arrow {
            display: block;
            width: 24px;
            height: 41px;
            position: absolute;
            top: 137px;
            z-index: 10;
            cursor: pointer;
            text-indent: -9999px;
            transform: translate3d(0, -50%, 0);
            transition: transform 0.3s ease 0s;


            &.slick-disabled {
                display: none !important;
            }
            &.slick-prev {
                left: 0;
                background: url('../../assets/images/arrow_blue_left.png') 0 0 no-repeat;
                &:hover {
                    transform: translate3d(-5px, -50%, 0);
                }
            }
            &.slick-next {
                right: 0;
                background: url('../../assets/images/arrow_blue_right.png') 0 0 no-repeat;
                &:hover {
                    transform: translate3d(5px, -50%, 0);
                }
            }
        }
    }

    &_subVariant_nature {
        .slick-arrow {
            &.slick-prev {
                background: url('../../assets/images/lp_nature_arrow_left.png') 0 0 no-repeat;
            }

            &.slick-next {
                background: url('../../assets/images/lp_nature_arrow_right.png') 0 0 no-repeat;
            }
        }
    }

    .slick-dots {
        text-align: center;
        margin-top: 20px;

        li {
            display: inline-block;
            margin: 0 10px;

            &:before {
                display: none;
            }

            &:hover,
            &.slick-active {
                button {
                    background: $primary80;
                }
            }

            button {
                font-size: 0;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                background: $neutral40;
                cursor: pointer;
            }
        }
    }
}
