.Hamburger{
        width: 24px;
        height: 17px;
        position: relative;
        justify-self: flex-end;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: $neutral90;
            border-radius: 100px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 7px;
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 14px;
                transform-origin: left center;
            }
        }

        &_isOpen {
            span {
                background-color: #fff;
                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: -1px;
                    left: 6px;
                }

                &:nth-child(2) {
                    width: 0;
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    top: 16px;
                    left: 6px;
                }
            }
        }
}
