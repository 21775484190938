.ContactInfoCMS {
    &-Summary {
        display: inline-block;

        &:last-of-type {
            margin-bottom: 50px;
        }

        &:not(&:last-of-type) {
            margin-bottom: 22px;
            padding-bottom: 22px;
            border-bottom: 1px solid #D9D9D9
        }

        a {
            font-size: inherit;
        }
    }

    &-Info {
        display: inline-block;
        margin-bottom: 50px;
    }

    &-Phone {
        font-size: 23px;
        font-weight: 700;
        line-height: 32px;
    }
}

