.LpAdvancedProductAlternately {
    @include container;

    .ImagesWithDescCMS {
        display: grid;
        grid-template-columns: 100%;
        gap: 40px;

        &_wide {
            .ImagesWithDescCMS {
                &-Block {
                    background-color: $advanced25;
                    border-radius: 30px;
                    margin: 0;
                    padding: 32px 84px;
                    max-width: none;
                    grid-template-columns: 1fr 1fr;

                    @include tablet {
                        padding: 32px 24px;
                    }
                }

                &-ButtonWrap {
                    & > * {
                        text-align: center;

                        @include tablet {
                            width: 100%;
                        }
                    }
                }

                &-Content {
                    padding: 0;
                }
            }
        }
    }
}
