/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogCategories.BlogCategories_isOverridden {
    margin-top: 40px;

    @include tablet {
        margin-top: 32px;
    }

    .BlogCategories {
        &-Title {
            padding: 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            color: $neutral70;
            text-transform: none;
            margin-bottom: 16px;
            border: none;
            text-align: left;
        }

        &-Reset {
            font-family: $font-filson-soft;
            font-weight: 400;
            font-size: 15px;
            text-align: center;
            width: 100%;
        }

        &-Category {
            display: inline-block;
            margin: 0 16px 16px 0;
            color: $neutral70;
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;

            &:hover,
            &:focus,
            &_isActive {
                color: $primary90;
            }

            &_isActive {
                font-weight: 500;
            }

            * {
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-align: inherit;
                color: inherit;

                &:hover,
                &:focus {
                    text-decoration: none;
                    font-size: inherit;
                    line-height: inherit;
                    text-align: inherit;
                    color: inherit;
                }
            }
        }

        &-Overlay {
            @include tablet {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
