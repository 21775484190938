/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageMagnifier {
    pointer-events: none;
    position: absolute;
    left: 110%;
    z-index: 101;
    &-ImageWrapper {
        transform: translate(-50%,-50%);
        position: relative;
        background: $white;
        height: 100%;
        .Image-Image img {
            position: absolute;
        }
        .Image_ratio_square {
            height: 100%;
            border: 2px solid $primary50;
            box-shadow: -3px 3px 15px 0px $primary50;
        }
    }
}

@include mobile {
    .ImageMagnifier {
        left: 0;
        top: 100%
    }
}
