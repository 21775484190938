.LpPrimaryGridSpecial {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include desktop {
        align-items: center;
        grid-gap: 80px;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &_isReverse {
        @include tablet {
            flex-direction: column-reverse;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        width: auto;
    }
}

.LpPrimaryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;

    @include tablet {
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
    }
}
