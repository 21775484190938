/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.PostsDetails {
    &.PostsDetails_isOverridden {
        font-family: $font-filson-soft;
        margin-top: 0;

        .ContentWrapper {
            max-width: initial;
            padding: 0;
            margin: 0;
        }

        .PostsDetails {
            &-Wrapper {
                --content-wrapper-width: 1052px;

                @include container;
            }

            &-Instagram {
                margin-bottom: 44px;
                padding-bottom: 44px;
                background-image: url("../../assets/instagram-bg.svg");
                background-position: bottom center;
                background-size: contain;
                background-repeat: no-repeat;

                & > * {
                    max-width: 470px !important;

                    @include tablet {
                        max-width: 80% !important;
                    }
                }
            }

            &-Hero {
                padding: calc(var(--header-height) + 36px) 0 0;
                margin-bottom: 24px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: calc(100% - 151px);
                    background-color: $primary5;
                }

                &Wrapper {
                    --content-wrapper-width: 1152px;

                    @include container;

                    .Image {
                        overflow: hidden;
                        border-radius: 15px;
                        padding-bottom: 49%;

                        img {
                            object-fit: cover;
                        }
                    }
                }
            }

            &-BackLink {
                display: inline-block;
                font-size: 15px;
                font-weight: 400;
                font-family: $font-filson-soft;
                padding: 13px 50px;
                width: 100%;
                height: 48px;
                border-width: 1px;
                text-align: center;
            }

            &-Header {
                text-align: center;
                margin-bottom: 33px;

                @include desktop {
                    margin-bottom: 66px;
                }
            }

            &-Description {
                margin-left: 75px;
                margin-top: 0;

                @include narrow-desktop {
                    margin-left: 30px;
                    margin-top: 0;
                }

                @include tablet {
                    margin-left: 0;
                }
            }

            &-Title {
                display: inline-block;
                text-transform: unset;
                color: $black;
                font-size: 40px;
                font-weight: 500;
                padding-bottom: 0;
                margin-bottom: 16px;
                line-height: 48px;
                border-bottom: none;
                text-align: center;

                @include tablet {
                    font-size: 32px;
                    line-height: 40px;
                }

                @include mobile {
                    font-size: 26px;
                    line-height: 34px;
                }
            }

            &-Image {
                border-radius: 30px;
                max-width: 100%;
                margin-bottom: 0;
            }

            &-Body {
                display: grid;
                align-items: flex-start;
                grid-template-columns: 100%;
                grid-gap: 66px;

                @include desktop {
                    grid-template-columns: 1fr 258px;
                }
            }


            &-VerifiedUser {
                padding: 32px;
                text-align: center;

                &Label, &Name {
                    font-size: 13px;
                    font-weight: 350;
                    line-height: 20px;
                }

                &Name {
                    font-weight: 500;
                }

                &Image {
                    max-width: 130px;
                    margin: 0 auto 30px;

                    .Image {
                        overflow: hidden;
                        border-radius: 100%;
                        padding-bottom: 100%;

                        &-Content {
                            left: 0;
                        }

                        img {
                            left: 0;
                            object-fit: cover;
                        }
                    }
                }
            }

            &-Sidebar {
                background-color: $white;
                box-shadow: 0px 40px 80px 0px rgba(82, 149, 200, 0.18);
                border-radius: 15px;

                @include tablet {
                    min-width: initial;
                    max-width: 100%;
                    display: block;
                }


                & > *:not(:first-child) {
                    &:not(.BlogRelatedProducts) {
                        border-top: 1px solid $primary20;
                    }

                    .ProductLinks {
                        border-top: 1px solid $primary20;
                    }
                }
            }

            &-TableOfContents {
                padding: 32px;

                &Heading {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 23px;
                    text-align: left;
                    margin: 0 0 24px;
                }

                &Item {
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 20px;
                    text-align: left;
                    margin: 0;
                    cursor: pointer;

                    span {
                        font-weight: 350;
                    }

                    &:not(:first-child) {
                        margin-top: 8px;
                    }
                }
            }

            &-Content {
                padding-bottom: 56px;
                margin: 0;

                &Wrapper {
                    margin-bottom: 64px;
                }


                h2, h3, h4 {
                    color: $neutral90;
                    margin-top: 56px;
                    margin-bottom: 24px;
                }

                h2 {
                    margin-top: 56px;
                    padding-bottom: 24px;
                    margin-bottom: 24px;
                    border-bottom: 1px solid $primary20
                }

                h2 {
                    font-size: 28px;
                    text-transform: none;
                }

                h3 {
                    font-size: 24px;
                }

                p {
                    font-size: 15px;
                    font-weight: 350;
                    line-height: 23px;
                    margin-bottom: 24px;
                }

                a {
                    font-size: 15px;
                    font-weight: 350;
                    line-height: 23px;
                }

                img {
                    border-radius: 30px;
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    margin: initial;
                    display: initial;
                }

                ul li {
                    font-size: 15px;
                    font-weight: 350;
                    line-height: 23px;

                    &::before {
                        content: '';
                        position: relative;
                        top: -3px;
                        display: inline-block;
                        margin-right: 10px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $primary50;

                        @include mobile {
                            top: -2px;
                        }
                    }
                }

                .PostsDetails-Flex {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 30px;
                    margin: 50px 0;

                    @include mobile {
                        flex-wrap: wrap;
                        margin: 30px 0;
                    }

                    img {
                        max-width: 35%;
                        @include mobile {
                            max-width: 100%;
                        }
                    }
                }
            }

            &-Categories {
                font-size: 13px;
                font-weight: 350;
                line-height: 20px;
                color: $primary90;
            }

            &-Category {
                display: inline-block;
                margin: 0 0 16px 0;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-align: left;

                &Separator {
                    margin: 0 8px 0 8px;
                }

                &:hover,
                &:focus,
                &_isActive {
                    color: $primary90;
                }

                &_isActive {
                    font-weight: 500;
                }

                * {
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    text-align: inherit;
                    color: inherit;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        font-size: inherit;
                        line-height: inherit;
                        text-align: inherit;
                        color: inherit;
                    }
                }
            }

            &-Info {
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                color: $neutral70;

                &Item {
                    display: inline-block;
                    margin: 0 0 8px 0;
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    text-align: left;

                    &Separator {
                        margin: 0 16px 0 16px;
                    }

                    svg {
                        margin-right: 6px;
                    }

                    span:last-of-type {
                        display: inline-flex;
                        align-items: center;
                    }

                    * {
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        text-align: inherit;
                        color: inherit;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            font-size: inherit;
                            line-height: inherit;
                            text-align: inherit;
                            color: inherit;
                        }
                    }
                }
            }
        }
    }

}
