.LpPrimaryHeadingContainer {
    @include container;

    .TypographyHeader {
        margin-block: 0;
    }
}

.LpPrimaryHeading2 {
    padding-top: 0;
    padding-bottom: 40px;
}

.LpPrimaryHeading3 {
    margin: 0 0 32px;

    @include tablet {
        margin: 22px 0 32px;
    }
}
