.post-list {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;
    margin-bottom: 40px;

    @include before-mobile {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
    }
}

.post-item {
    &-image {
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 26px;
        aspect-ratio: 4/3;
        object-fit: cover;
    }
}
