.SayAboutUs {
    @include container;

    margin-top: 80px;
    margin-bottom: 80px;
    font-family: $font-filson-soft;

    &-Quotes {
        .AdvoxSlider {
            .slick {
                &-list {
                    padding: 20px 0 50px 0 !important;
                }

                &-dots {
                    margin-top: 32px;
                }

                &-arrow {
                    transform: translateY(-50%);
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    cursor: pointer;

                    svg {
                        height: 52px;
                        width: 52px;

                        path {
                            stroke: $primary90;
                        }

                        rect {
                            fill: $primary10;
                        }
                    }
                }

                &-prev {
                    left: -14px;

                    @include desktop {
                        left: -26px;
                    }
                }

                &-next {
                    right: -14px;

                    @include desktop {
                        right: -26px;
                    }
                }
            }

            .slick-slide {
                .SayAboutUs-Quote {
                    --quote-y: 0;
                    --quote-scale: 1;
                    --quote-bg: #{$neutral10};
                    --quote-text: #{$neutral40};

                    @include desktop {
                        --quote-y: 40px;
                        --quote-scale: 1;
                    }
                }

                &.slick-current {
                    .SayAboutUs-Quote {
                        --quote-y: 0;
                        --quote-scale: 1;
                        --quote-bg: #{$primary10};
                        --quote-text: #{$primary90};
                        z-index: 1;

                        @include desktop {
                            --quote-y: 0;
                            --quote-scale: 1.1;
                        }
                    }
                }
            }

            img {
                width: initial;
                display: initial;
                max-width: 100%;
            }
        }
    }

    &-Title {
        text-align: center;
        margin: 0 0 32px;

        span {
            color: $primary50;
        }
    }

    &-Text {
        transition: all 0.2s ease;
        background-color: var(--quote-bg);
        color: var(--quote-text);
        padding: 42px;
        font-size: 19px;
        font-weight: 500;
        line-height: 26px;
        border-radius: 30px;

        * {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
        }

        .SayAboutUs-QuotationMark {
            font-size: 64px;
            line-height: 64px;
            margin-bottom: -18px;
        }
    }

    &-Quote {
        transition: transform 0.2s ease;
        transform: translateY(var(--quote-y)) scale(var(--quote-scale));

        & > a, & > img {
            text-align: center;
            display: block;
            margin: 24px auto 0;
            max-width: 50%;
        }
    }
}
