/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ScrollToTop {
    --scroll-to-top-size-mobile: 42px;
    --scroll-to-top-size: 42px;
    --scroll-to-top-bg: #{$primary50};
    --scroll-to-top-bottom: calc(var(--footer-nav-height, 0px) + 24px);

    position: fixed;
    z-index: 9;
    right: 30px;
    bottom: 30px;
    width: var(--scroll-to-top-size);
    height: var(--scroll-to-top-size);
    background: var(--scroll-to-top-bg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.281769);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    transition: all 0.2s ease;

    &_isVisible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    @include tablet {
        width: var(--scroll-to-top-size-mobile);
        height: var(--scroll-to-top-size-mobile);
        bottom: var(--scroll-to-top-bottom);
        right: 20px;
    }

    @include mobile {
        width: var(--scroll-to-top-size-mobile);
        height: var(--scroll-to-top-size-mobile);
        bottom: var(--scroll-to-top-bottom);
        right: 20px;
    }

    .ChevronIcon {
        fill: $white;
    }
}
