/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogSearchOverlay {
    top: 0;
    left: 0;
    width: 100%;
    border: none;
    padding: 90px 16px 24px 24px;
    border-radius: 15px;
    box-shadow: 0px 40px 80px 0 rgba(82, 149, 200, 0.18);
    transform: translate(-24px, -14px);
    max-height: none;
    z-index: 10;

    @include desktop {
        min-width: 560px;
        transform: translate(-24px, -24px);
    }

    @include tablet {
        &.Overlay:not(.Overlay_isStatic) {
            padding: 80px 16px 24px 24px;
            margin-top: 0;
            position: absolute;
            height: auto;
            top: 0;
        }
    }

    @include mobile {
        min-width: calc(100% + 48px);
    }

    &-Results {
        padding: 0;
        overflow: auto;
        max-height: 224px;

        @include scrollbar($scrollbar-thumb-color: $primary90, $scrollbar-rail-color: $primary5);

        & > p {
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
        }
    }

    &-Wrapper {
        .Image {
            overflow: hidden;
            border-radius: 5px;
            padding-bottom: 66.66%;

            img {
                object-fit: cover;
            }
        }
    }

    &-Criteria {
        color: $neutral70;
    }

    &-Title {
        font-size: 13px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: $neutral70;
        transition: color .3s ease-in-out;
    }

    &-Item {
        padding: 0;
        margin-bottom: 16px;

        &::after {
            content: none;
            display: none;
        }

        &_isNoImage {
            .BlogSearchOverlay-Wrapper {
                grid-template-columns: 1fr;
            }
        }
    }
}
