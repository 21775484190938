.CategoryPageBox{
    font-family: 'filson-soft', sans-serif;
    padding: 25px;
    background-color: $primary5;
    &-Header{
        font-size: 13px;
        font-weight: 700;
        color: $primary90;
        text-transform: uppercase;
        margin-bottom: 20px;
        line-height: 1.5;
        letter-spacing: 0.1em;
    }

    &-ListItem{
        font-weight: 700;
        font-size: 13px;
        color: $neutral90;
        letter-spacing: 0.1em;
        display: grid;
        grid-template-columns: 16px auto;
        grid-gap: 15px;

        &:not(:last-of-type){
            margin-bottom: 15px;
        }

        &::before{
            content: '';
            position: static;
            display: inline-block;
            width: 16px;
            height: 13px;
            padding: 0;
            margin: 3px 0 0 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('../../assets/svg/tick.svg');
        }
    }
}

@include tablet{
    .CategoryPageBox{
        display: none;
    }
}
