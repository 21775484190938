.FeedbackCMS {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom: 100px;
    justify-content: center;
    align-content: center;
    background-image: url("../../assets/svg/Vector.svg");
    background-repeat: no-repeat;
    background-position: 0 -50%;

    &-Wrapper {
        margin: 0 auto;
        max-width: 1440px;
    }

    &-Description {
        margin-bottom: 200px;
        text-align: center;
    }

    &-Person-Tile-Description {
        color: $neutral70;
        margin-bottom: 20px;
    }

    &-Person_main {
        display: flex;
        justify-content: center;
        margin-bottom: 145px;
        margin-left: 310px;

        .FeedbackCMS-Person-Tile {
            max-width: 380px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .FeedbackCMS-Person-Img {
            width: 310px;
            margin-right: 62px;

            img {
                border-radius: 50%;
            }
        }
    }

    .FeedbackCMS-Person_wrap {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        max-width: 1218px;
        grid-gap: 72px;
        padding: 0 15px;

        .FeedbackCMS-Person {
            max-width: 350px;
        }

        .FeedbackCMS-Person-Tile-Name {
            margin-top: 54px;
        }

        .FeedbackCMS-Person-Img {
            width: 180px;

            img {
                border-radius: 50%;
            }
        }
    }
}

@include tablet {
    .FeedbackCMS {
        background-position: -490px, 600px;
        padding: 0 32px 0 62px;

        &-Description {
            font-size: 30px;
            margin-bottom: 100px;
        }

        &-Person_main {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 100px;

            .FeedbackCMS-Person-Img {
                width: 210px;
            }

            .FeedbackCMS-Person-Tile {
                max-width: 100%;
                flex: 1;
            }
        }

        .FeedbackCMS-Person_wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            padding: 0;

            grid-gap: 100px;


            .FeedbackCMS-Person-Img {
                width: 140px;
                margin-right: 50px;
            }

            .FeedbackCMS-Person {
                display: flex;
                max-width: 100%;
                align-items: center;

                .FeedbackCMS-Person {
                    &-Tile {
                        flex: 1;
                    }

                    &-Tile-Name {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}


@include mobile {
    .FeedbackCMS {
        padding: 0 24px;
        background-position: -490px -146px;

        &-Description {
            font-size: 28px;
            margin-bottom: 40px;
        }

        &-Person {
            flex-direction: column;
        }

        &-Person_main {
            .FeedbackCMS-Person-Img {
                margin-right: 0;
            }
        }

        &-Person-Tile-Description {
            text-align: center;
        }

        .FeedbackCMS-Person_wrap,
        .FeedbackCMS-Person_main {
            flex-direction: column;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            max-width: 100%;

            .FeedbackCMS-Person-Tile {
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                max-width: 100%;
            }
        }

        .FeedbackCMS-Person_wrap {
            grid-gap: 50px;

            .FeedbackCMS-Person {
                align-items: flex-start;

                &-Img {
                    margin-bottom: 15px;
                }

                &-Tile {
                    position: static;
                }

                &-Tile-Name {
                    position: absolute;
                    top: 0;
                    left: 168px;
                    height: 140px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                }

                &-Tile-Description {
                    text-align: left;
                }

                &-Tile-Social {
                    width: 100%;
                }
            }
        }
    }
}

