/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0E0F11;
    --breadcrumbs-color: #AAAFB6;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    @include desktop {
        margin-top: 100px;
    }

    @include tablet {
        margin-top: var(--header-height);
    }

    @include mobile {
        margin-top: var(--header-height);
    }

    &-List {
        list-style: none;
        padding: 20px 0;
    }
}
