/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuizFirstSlide {
    @include desktop {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 900px;
                width: 100%;
                min-height: 525px;
                margin: auto;
                background-color: #FFFFFF;
                border-radius: 25px;
                box-shadow: 0px 40px 80px 0px rgba(82, 149, 200, 0.18);
            }
        }
    }

    &-Content {
        display: grid;
        justify-items: center;
        gap: 24px;

        @include desktop {
            max-width: 400px;
        }

        @include before-desktop {
            padding: 35% 24px 0;
        }

        .TypographyHeader {
            color: $neutral80;
            text-align: center;
            font-family: $font-filson-soft;
            font-size: 30px;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.6px;
        }

        .TypographyParagraph {
            color: $neutral70;
            text-align: center;
            font-family: $font-filson-soft;
            font-size: 15px;
            font-weight: 350;
            line-height: 150%;
        }
    }
}
