/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.NewsletterSubscription {
    padding: 42px;
    background-color: $primary50;
    width: 490px;
    display: flex;
    flex-direction: column;

    .ButtonContainer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .Button {
        color: #9DC5DF;
        font-weight: 600;
    }

    &-HeadingCMS {
        color: $neutral5;
        margin: 48px 0 25px;
        padding-left: 10px;
        letter-spacing: initial;
    }

    .Field {
        margin-top: 20px;
        &-Label {
            margin-bottom: 5px;
            &_isRequired {
                &::after{
                    content: '*';
                    color: var(--color-white);
                }
            }
        }
        &_type_checkbox {
            margin-top: 10px;
        }
    }

    .Form {
        width: 100%;
    }

    .FieldForm {
        input {
            height: 49px;
        }
        &-Fieldset {
            max-width: 550px;
            margin: 0 auto;
        }
        &-Agreement {
            margin-top: 10px;
        }
    }
}

@include tablet {
    .NewsletterSubscription {
        width: 100%;

        &-HeadingCMS {
            padding: 0;
            margin-top: 0;
            text-align: center;
        }
    }
}
