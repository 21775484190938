.ButtonsWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
    }

    & > .Button {
        margin: 0 15px 15px 0;
    }
}

