.LpVitalProducts {
    position: relative;

    .LpPrimaryWrapper {
        @include container;
    }

    .LpPrimaryProductAlternately {
        position: relative;
        z-index: 1;
    }

    &::after {
        @include desktop {
            content: '';
            width: 152px;
            height: 500px;
            position: absolute;
            top: 550px;
            left: 0;
            background: url('../../../../assets/images/vital_decoration_2.png') 0 0/cover no-repeat;
        }
    }
}
