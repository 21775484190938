.LpEcoBioPet {
  margin: 80px 0 60px;

  .TypographyHeader_heading_h2 {
    position: relative;
    z-index: 2;
    margin-top: 0;
    @include desktop {
      margin-bottom: 0;
    }
  }

  &-Grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    @include desktop {
      max-width: 1380px;
      grid-template-columns: auto 45.3%;
      grid-gap: 108px;
      margin: -85px auto 0
    }
  }

  &-Image {
    max-width: 625px;
    justify-self: center;
    order: -1;
    @include desktop {
      justify-self: start;
      order: 0;
    }
  }

  &-Text {
    @include tablet {
      text-align: center;
    }
    @include desktop {
      padding: 70px 0 0;
      align-self: center;
    }

    .LpEco-SubTitle {
      margin-bottom: 33px;
      @include desktop {
        text-align: left;
      }
    }

    .TypographyParagraph {
      font-size: 27px;
      line-height: 42px;
    }
  }
}
