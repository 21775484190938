.Plans{
    padding: 0 24px;

    &-Content {
        display: grid;
        grid-template-columns: auto 610px;
        grid-gap: 100px;
        max-width: 1130px;
        margin: 130px auto;
    }

    &-Header {
        margin: 35px 0 50px 0;
    }

    &-Paragraph {
        &:not(:last-of-type) {
            margin-bottom: 50px;
        }
    }
}

@include tablet {
    .Plans {
        &-Content {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
            margin: 75px auto;
        }

        &-Image {
            justify-self: start;
        }

        &-Header {
            margin-top: 0;
            margin-bottom: 20px;
        }

        &-Paragraph {
            font-size: 15px;
            &:not(:last-of-type){
                margin-bottom: 20px;
            }
        }
    }
}

@include mobile {
    .Plans {
        &-Content {
            grid-template-columns: 1fr
        }

        &-Image {
            display: none;
        }
    }
}
