/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-recent-posts-border: 1px solid lightgray;
}

.BlogRecentPosts {
    &-PostCard {
        display: flex;
        padding: 10px 0;
        border-top: var(--blog-recent-posts-border);

        &:last-child {
            border-bottom: var(--blog-recent-posts-border);
        }
    }

    &-PostTitle {
        font-weight: 500;
        margin-top: 0;
    }

    &-PostTitlePlaceholder {
        &::after {
            content: 'aaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-Title {
        text-align: center;
        text-transform: uppercase;
    }

    &-ImageWrapper {
        flex-basis: 40%;
    }

    &-Wrapper {
        display: block;
        flex-basis: 60%;
    }

    &-Date {
        position: absolute;
        bottom: 0;
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }
}
