/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Breadcrumb {
    $crumb-padding: 12px;

    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;

    &:last-child {
        margin-bottom: 0;

        .Breadcrumb-Link {
            color: var(--breadcrumbs-active-color);
        }
    }

    @include mobile {
        font-size: 14px;
    }

    &-SeparateIcon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-inline: 8px;
        color: var(--breadcrumbs-color);
    }

    &:last-child {
        .Breadcrumb-SeparateIcon {
            display: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        text-transform: capitalize;
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: 350;
        font-size: 13px;
        line-height: 20px
    }
}
