.LpPrimaryGoed {
    @include container;

    &_vital {
        margin-block: 64px;

        @include desktop {
            margin-block: 114px;
        }

        .LpPrimaryGoed-Grid {
            background-color: $primary10;
        }
    }

    &:not(&_vital) {
        margin-bottom: 80px;

        @include desktop {
            margin-bottom: 230px;
        }
    }

    &-Grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 32px;
        border-radius: 30px;
        padding: 32px 24px;
        background-color: $marine;

        @include desktop {
            padding: 64px 168px;
            grid-template-columns: 0.5fr 1fr;
            grid-gap: 92px;
            align-items: center;
        }
    }

    &-Image {
        text-align: center;

        & > img {
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }

    .TypographyHeader {
        margin-block: 0 16px;
    }
}
