.LpEcoCleanFlake {
    @include tablet {
        margin-block: 48px 40px;
    }

    .LpPrimaryWrapper {
        @include container;

        .TypographyHeader {
            margin-top: 0;
            margin-bottom: 8px;
            line-height: 140%;
            letter-spacing: initial;

            &.TypographyHeader_heading_h2 {
                @include tablet {
                    line-height: 140%;
                }
            }

            &.TypographyHeader_heading_h1 {
                margin-bottom: 0;
            }

            &.TypographyHeader_heading_h3 {
                line-height: 120%;

                @include tablet {
                    line-height: 140%;
                    font-size: 18px;
                }
            }
        }
    }

    &-Image {
        margin-left: auto;
        margin-right: auto;
        max-width: 1100px;
        margin-top: 24px;
        display: none;

        @include desktop {
            display: block;
        }
    }

    &-SmallImage {
        margin-top: 12px;
        display: block;

        @include desktop {
            display: none;
        }
    }
}

