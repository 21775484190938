/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MapWithShops {
    margin-block: 40px;

    @include container;

    &-Heading {
        margin-block: 0 24px;
    }

    &-Header {
        margin-block-end: 24px;
    }

    &-Grid {
        display: grid;
        grid-template-columns: 1fr;

        @include desktop {
            grid-template-columns: 1fr 2fr;
            grid-gap: 110px;
        }
    }

    &-Content {
        &::before {
            content: "";
            display: block;
            padding-block-end: 56.25%;
        }

        @include mobile {
            &_type_map {
                order: -1;
                margin-inline: -16px;

                &::before {
                    padding-block-end: calc(100% + 32px);
                }
            }

            &_type_list {
                margin-inline-end: -8px;

                &::before {
                    padding-block-end: 100%;
                }
            }
        }

        & > * {
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-Hint {
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: 0;
        transform: translate(-50%, -58px);
        width: 230px;
        max-width: 230px;
        display: grid;
        grid-template-columns: 24px 161px;
        align-items: start;
        gap: 12px;
        padding-block: 17px;
        padding-inline: 16px;
        font-family: $font-filson-pro;
        background-color: $white;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-inline: 10px solid transparent;
            border-block-start: 15px solid $white;
        }

        svg {
            pointer-events: none;
            flex: 0 0 24px;
            max-width: 100%;
        }

        &-Close {
            position: absolute;
            right: 17px;
            top: 17px;
            height: 18px;
            width: 18px;
            z-index: 1;
        }

        &-Place {
            width: 100%;
            padding-right: 18px;
        }

        &-Name, &-Address {
            color: $primary90;
        }

        &-Name {
            font-weight: 600;
            margin-block: 0 4px;
        }

        &-Address {
            margin-block: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        &-Link {
            display: inline-block;
            margin-block: 4px 0px;
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
        }
    }

    &-Marker {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        transform: translateX(-50%);
        width: 38px;
        height: 42px;

        &_isCluster {
            width: 32px;
            height: 32px;
            background-color: $default-primary-base-color;
            color: $white;
            border-radius: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 32px;
            box-shadow: 0 0 0 10px rgba($default-primary-base-color, 0.2);
            text-align: center;
        }
    }

    &-Map {
        overflow: hidden;
        border-radius: 30px;
    }

    &-List > div > div {
        @include scrollbar($primary50, $primary5);

        padding-inline-end: 16px;
    }

    &-ItemSeparator {
        display: block;
        height: 8px;
        width: 100%;
    }

    &-Item {
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-gap: 12px;
        align-items: start;
        padding-block: 16px;
        padding-inline: 16px;
        margin-right: 8px;
        margin-block: 0 !important;
        border-radius: 16px;
        background-color: $white;
        cursor: pointer;

        &:hover {
            @include desktop {
                background-color: $primary5;
            }
        }

        &-Wrapper {
            &_selected .MapWithShops-Item {
                background-color: $primary5;
            }

            &_lastChild {
                margin: 0;
                border-bottom: 0;
            }

            &::before {
                display: none;
            }
        }

        svg {
            flex: 0 0 21px;
            max-width: 100%;
        }

        &-Name, &-Address {
            color: $primary90;
        }

        &-Name {
            font-size: 17px;
            line-height: 23px;
            font-weight: 600;
            margin-block: 0 4px;
        }

        &-Address {
            margin-block: 0;
            font-weight: 400;
            font-size: 17px;
            line-height: 23px;
        }

        &-Link {
            display: inline-block;
            margin-block: 4px 0px;
            font-size: 17px;
            line-height: 23px;
        }
    }

    &-Search {
        grid-area: search;

        input {
            height: 48px;
            padding: 12px 20px 12px 20px;
            width: 100%;
            border-radius: 100px;
            font-size: 12px;
            background-color: $white;
            border-color: $primary90;

            @include desktop {
                max-width: 320px;
            }
        }
    }
}
