.LpEcoQuality {
  margin-bottom: 80px;

  h2 + h3 {
    top: 0;
  }

  .TypographyHeader_heading_h1 {
    margin: 0 0 60px;
    text-transform: uppercase;
  }

  .TypographyHeader_heading_h2 {
    margin: 0 0 54px;
  }

  &-Grid {
    display: flex;
    gap: 70px;
    max-width: 1330px;
    margin: 0 auto 60px;
    &:last-child {
      margin-bottom: 0;
    }
    @include tablet {
      gap: 30px;
      flex-wrap: wrap;
    }
  }

  &-Image {
    flex: 0 0 auto;
    @include tablet {
      width: 100% !important;
      text-align: center;
    }

    img {
      max-width: 100%;
      width: auto;
    }
  }

  &-Content {
    @include wide-desktop {
      padding: 60px 0 0;
    }

    &_isReverse {
      @include tablet {
        order: 2;
      }
    }

    p {
      margin-bottom: 30px;
      font-family: "filson-soft", sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 133%;
      color: $blue1;
      @include desktop {
        margin-bottom: 68px;
        font-size: 34px;
      }
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}
