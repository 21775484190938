/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --posts-details-title-size: 28px;
}

.PostsDetails {
    .ContentWrapper {
        max-width: 1440px;
        padding: 0 10px;
        margin: auto;

        @include desktop {
            padding: 0 40px;
        }
    }

    &-Wrapper {
        padding-bottom: 30px;

        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        @include desktop {
            display: flex;
        }
    }

    &-Description {
        @include desktop {
            margin-left: 3rem;
        }

        @include mobile {
            order: 1;
        }

        .Image_ratio_square {
            padding-bottom: 0;
        }

        .Image-Content {
            position: relative;
        }

        .PostDetails-MediaGallery {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);

            @include narrow-desktop() {
                grid-template-columns: repeat(3, 1fr);
            }

            @include wide-desktop() {
                grid-template-columns: repeat(3, 1fr);
            }

            .Image {
                border: solid grey 1px;
                padding: 30%;
                margin: auto;
            }
        }
    }

    &-Sidebar {
        flex-basis: 30%;
        margin-top: 0;
        min-width: 220px;
        max-width: 280px;

        @include mobile {
            display: none;
        }
    }

    &-Content {
        p,ul,li {
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
        }

        .Image-Image {
            position: relative;
            width: 100%;
            height: 100%;
        }
    
        margin-top: 15px;
    }

    &-Title {
        text-align: left;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        @include desktop{
            font-size: 3.5rem;
            line-height: 4rem;
        }
        margin-top: 0;
        margin-bottom: 1.2rem;
    }

    &-Date,
    &-Author {
        display: block;
        text-align: right;
    }

    &-ContentPlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-Iframe {
        margin: 0 auto 1.2rem;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
        }
    }

    .slick-dots {
        li {
            background-color: #9B2F34;
        }
    }

    .slick-slider .slick-dots .slick-active {
        background-color: #9B2F34;
    }
}
