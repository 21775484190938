.Footer {
    max-width: 1440px;
    margin: 80px auto 0 auto;
    padding-left: 25px;

    &-Content {
        display: flex;
        justify-content: space-between;
        &-Links {
            display: flex;
            margin-top: 100px;
            padding-right: 70px;
            gap: 100px;

            &-List {
                &:last-child {
                    padding-top: 44px;
                }
                p {
                    color: $neutral90;
                    margin-bottom: 22px;
                }

                &-Wrapper {
                    display: flex;
                    gap: 60px;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }

            li {
                padding: 0;
                padding-bottom: 16px;
                a {
                    color: $neutral70;
                }

                &::before {
                    content: ''
                }
            }
        }
    }
}

@include tablet {
    .Footer {
        padding-left: 0;
        &-Content {
            flex-direction: column;
            &-Links {
                gap: 70px;
                padding: 0 25px;
                margin-top: 70px;

                &-List {
                    &-Wrapper {
                        gap: 30px;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Footer {
        &-Content {
            &-Links {
                margin-top: 0;
                display: block;

                li {
                    padding-bottom: 10px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

@include mobile-xs {
    .Footer {
        &-Content {
            &-Links {
                gap: 15%;
                padding: 0 25px;
                justify-content: space-evenly;
            }
        }
    }
}
