/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MarketingClaims {
    grid-column-start: 2;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 650px;
    gap: 22px;
    margin-bottom: 50px;
    &-MarketingClaimButton {
        font-family: "filson-pro", sans-serif;
        width: 31%;
        min-width: 160px;
        display: flex;
        justify-content: center;
        background: $primary50;
        border-radius: 50px;
        p {
            color: $white;
            padding: 11px 10px;
            font-weight: 700;
        }

        &::after {
            content: url("../../assets/svg/tick-white.svg");
            width: 20px;
            height: auto;
            margin: auto 0;
        }
    }
}

@include tablet {
    .MarketingClaims {
        gap: 20px;
        &-MarketingClaimButton {
            width: 45%;
            min-width: 140px;
            p {
                padding: 15px 4px;
            }
        }
    }
}

@include mobile {
    .MarketingClaims {
        grid-column-start: 1;
        justify-content: space-evenly;
        margin: 0 auto 50px auto;
        gap: 15px;
        &-MarketingClaimButton {
            p {
                font-size: 13px;
            }
        }
    }
}
