.LpPrimaryCounter {
    display: inline-block;
    background-color: $nature50;
    padding: 10px;
    width: 80px;
    height:  48px;
    color: $white;
    font-size: 30px;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
    border-radius: 100px;
    margin-bottom: 16px;

    &_inverse {
        background-color: $white;
        color: $nature50;
    }
}
