/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageZoomPopup {
    &.Popup {
        .Popup-Content {
            max-height: 100%;
            max-width: 100%;
            padding-top: calc(var(--popup-content-padding, 0px) + 30px);
            overflow: hidden;
        }

        .Popup-CloseBtn {
            right: 0;
            left: unset;
            top: -54px;
            transform: translateY(50%);
        }
    }

    .ProductGallery-SliderImage {
        cursor: zoom-in;
    }

    .Slider-Arrow {
        display: initial;
        width: 35px !important;

        &_isNext {
            right: 0 !important;
        }

        &_isPrev {
            left: 0 !important;
        }
    }
}
