.SocialLinksCMS {
    z-index: 1;
    display: flex;
    margin-bottom: -41px;

    &::before {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $primary10;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &-Links {
        background-color: $white;
        display: flex;
        padding: 0 40px 0 0;
        gap: 20px;
        position: relative;

        a {
            margin: auto;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: calc(50% + 1px);
            background: $neutral5;
            z-index: -1;
            bottom: 0;
        }
    }

    &-Osavi {
        padding-right: 25px;

        span {
            display: block;
            width: 141px;
            height: 57px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../../assets/svg/logo-osavi.svg");
        }
    }

    &-Instagram, &-Facebook, &-Twitter, &-Youtube, &-LinkedIn, &-TikTok {
        span {
            display: block;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-size: contain;
            transition: $defaultTransition;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    &-Instagram span {
        background-image: url("../../assets/svg/instagram.svg");
    }

    &-Facebook span {
        background-image: url("../../assets/svg/facebook.svg");
    }

    &-Twitter span {
        background-image: url("../../assets/svg/twitter.svg");
    }

    &-LinkedIn span {
        background-image: url("../../assets/svg/linkedin.svg");
    }

    &-TikTok span {
        background-image: url("../../assets/svg/tiktok.svg");
    }
}

@include tablet {
    .SocialLinksCMS {
        &-Links {
            padding: 0 40px 0 25px;
        }
    }
}

@include mobile {
    .SocialLinksCMS {
        border-bottom: 0;
        border-top: 1px solid $primary10;
        margin-inline: 25px;
        margin-bottom: 0;

        &-Links {
            padding: 0;
            margin: 40px auto 40px 0;
            width: 100%;
            max-width: 336px;
            gap: initial;
            justify-content: space-between;
        }

        &-Osavi span {
            min-width: 105px;
            min-height: 43px;
            width: 100%;
            height: 100%;
        }

        &-Instagram, &-Facebook, &-Twitter, &-Youtube, &-LinkedIn, &-TikTok {
            padding-right: 10px;
        }
    }
}
