.LpPrimaryWrapper {
  max-width: 1530px;
  padding: 0 25px;
  margin: 0 auto;
  @include tablet {
    padding: 0 85px;
  }
  @include mobile {
    padding: 0 25px;
  }
}