.LpPrimaryProducts {
    position: relative;

    .LpSecondaryProducts {
        position: relative;
        margin-bottom: 32px;
        z-index: 3;

        .ContentTabs-Box {
            &-Content {
                margin-top: 12px;
            }
        }
    }
}
