/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.PostsListing {
    &-Breadcrumbs {
        &.ContentWrapper {
            @include container;
        }
    }

    &.PostsListing_isOverridden {
        font-family: $font-filson-soft;
        margin-top: 0;

        .ContentWrapper {
            @include container;
        }

        .PostsListing {
            &-Hero {
                padding: calc(var(--header-height) + 48px) 0 12px;
                margin-bottom: 64px;

                @include desktop {
                    margin-bottom: 100px;
                    padding: calc(var(--header-height) + 48px) 0 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $primary5;

                    @include desktop {
                        height: calc(var(--header-height) + 175px);
                    }
                }

                &Wrapper {
                    @include container;
                }
            }

            &-Sidebar {
                @include narrow-desktop {
                    max-width: 280px;
                }

                @include tablet {
                    display: none;
                }
            }

            &-Title {
                font-size: 44px;
                font-weight: 700;
                line-height: 53px;
                text-transform: none;
                margin-bottom: 28px;

                @include tablet {
                    font-size: 36px;
                }

                @include mobile {
                    font-size: 26px;
                }
            }

            &-ColumnWrapper {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 89px;
                margin-bottom: 100px;

                @include desktop {
                    grid-template-columns: 230px 1fr;
                }
            }

            &-GridWrapper {
                margin: 0;
            }

            &-Grid {
                max-width: unset;
                grid-template-columns: 1fr;
                grid-gap: 64px;

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mobile {
                    grid-template-columns: 1fr;
                }
            }

            &-LoadMore {
                &Wrapper {
                    padding-top: 64px;
                }

                &Button {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: $font-filson-soft;
                    padding: 17px 50px;
                    width: 100%;
                    height: 60px;
                }
            }
        }
    }
}

