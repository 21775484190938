$heading-color: #000;

.TypographyHeader {
    color: $heading-color;
    font-family: "filson-soft", sans-serif;
    line-height: 150%;
    font-weight: 700;
    text-transform: none;

    &_color_white {
        color: $neutral5;
    }

    &_color_black {
        color: $neutral90;
    }

    &_color_blue {
        color: $primary50;
    }

    &_color_nature {
        color: $nature
    }

    &_goals {
        height: 32px;
        margin-bottom: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.32px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
            color: inherit;
            font-size: inherit;
            &:hover {
                color: inherit;
            }
        }
    }

    &_color_advanced {
        color: $advanced;
    }

    &_color_vital {
        color: $vital;
    }

    &_color_eco {
        color: $blue1;
    }

    &_color_primary_90 {
        color: $primary90;
    }

    &_big {
        font-size: 25px;
        line-height: 28px;
    }

    &_heading_h1, &_heading_h2 {
        line-height: 120%;
    }

    &_heading_h1, &_heading_h4, &_heading_h6 {
        letter-spacing: 0.1em;
    }

    &_heading_h2, &_heading_h3 {
        font-weight: 500;
    }

    &_heading_h1 {
        font-size: 44px;
        margin-bottom: 48px;
    }

    &_heading_h2 {
        font-size: 40px;
        letter-spacing: 0.02em;
        margin-bottom: 40px;
    }

    &_heading_h3 {
        font-size: 30px;
        margin-bottom: 32px;
    }

    &_heading_h4 {
        font-size: 21px;
        margin-bottom: 24px;
    }

    &_heading_h5 {
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 100%;
        margin-bottom: 20px;
    }

    &_heading_h6 {
        font-size: 12px;
        margin-bottom: 16px;
    }

    &_align_center {
        text-align: center;
    }

    &_border {
        &_advanced, &_orange {
            display: inline-block;
            border-bottom: 1px solid;
            padding-bottom: 6px;
        }

        &_advanced {
            border-color: $advanced;
        }

        &_orange {
            border-color: $orange;
        }
    }

    &_noMargin {
        margin: 0;
    }
}

@include tablet {
    .TypographyHeader {
        &_heading_h1 {
            font-size: 36px;
        }

        &_heading_h2 {
            font-size: 30px;
            margin-bottom: 30px;
        }

        &_noMargin {
            margin: 0;
        }
    }
}

@include mobile {
    .TypographyHeader {
        &_heading_h1,
        &_heading_h2,
        &_heading_h3 {
            font-size: 26px;
        }

        &_heading_h1 {
            font-size: 28px;
            margin-bottom: 40px;
        }

        &_heading_h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        &_noMargin {
            margin: 0;
        }
    }
}
@include tablet {
    .TypographyHeader {
        &_heading_h1 {
            font-size: 36px;
        }
        &_heading_h2 {
            font-size: 32px;
        }
        &_heading_h3 {
            font-size: 26px;
        }
    }
}
@include mobile {
    .TypographyHeader {
        &_heading_h1,
        &_heading_h2,
        &_heading_h3 {
            font-size: 26px;
        }
    }
}
