.LpPrimaryStandard {
    padding-inline: 24px;
    margin-block: 24px;

    @include desktop {
        margin-block: 40px;
    }

    &-Wrap {
        @include container;

        @include mobile {
            padding-inline: 24px;
        }

        padding-inline: 48px;
        padding-block: 48px;
        border-radius: 30px;
        background-color: $nature25;
        background-image: url("../../../../assets/svg/lp_nature_standard_mobile_background.svg");
        background-position: right bottom;
        background-repeat: no-repeat;

        @include desktop {
            padding-block: 117px;
            background-image: url("../../../../assets/svg/lp_nature_standard_desktop_background.svg");
        }
    }

    &-Image {
        width: 100%;
        order: initial;

        @include desktop {
            order: -1;
        }

        img {
            margin-bottom: 24px;
        }

        &__desc {
            position: relative;
            max-width: 680px;
            padding: 10px 0 0 0;
        }
    }

    &-Content {
        @include desktop {
            max-width: 620px;
        }

        .TypographyHeader {
            margin-block: 0 16px;
        }
    }
}
