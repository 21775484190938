/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogSearchBar_isOverridden {
    .BlogSearchBar {
        &-Search {
            padding: 0;
            z-index: 20;

            .SearchIcon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0px;
                height: 18px;
                width: 18px;
                fill: $neutral70;
            }
        }

        &-SearchWrapper {
            margin: 0;
        }

        &-SearchField {
            height: 40px;
            padding: 10px 18px 10px 0;
            border-radius: 0;
            font-weight: 500;
            line-height: 20px;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $neutral70;

            &, &::placeholder {
                font-size: 13px;
                color: $neutral70;
                font-weight: 350;
                line-height: 20px;
            }

            &:focus {
                border: none;
                font-size: 13px;
                font-weight: 350;
                line-height: 20px;
                border-radius: 0;
                color: $primary90;
                border-bottom: 1px solid $primary90;

                &::placeholder {
                    color: $primary90;
                }

                & + * {
                    color: $primary90;
                    fill: $primary90;
                }
            }
        }
    }
}
