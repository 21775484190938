/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        z-index: 110;
        position: absolute;
        transform: translateY(100%);
        bottom: -12px;
        right: 0;
        width: 100%;
        padding: 0;
        min-width: 368px;
        box-shadow: 0px 0px 20px 0px #{rgba($black, 0.15)};

        @include mobile {
            min-width: auto;
        }

        ul, p {
            padding: 16px;
        }

        li:last-child {
            margin-bottom: 0;
        }

        .simplebar-track.simplebar-vertical {
            margin-block: 40px;
            background: #F1F1F1;
            border-radius: 7px;
            width: 5px;
            right: 2px;

            .simplebar-scrollbar:before {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: $primary90;
            }
        }

        .simplebar-scrollbar.simplebar-visible:before {
            opacity: 1;
        }
    }
}
