/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.InstallPromptIOS {
    left: 0;
    bottom: 0;
    position: fixed;
    z-index: 4;
    width: 100%;
    margin: 0 auto;
    background-color: #F5F5F5;
    &-Content {
        padding-top: 4px;
        flex-direction: column;
    }
    &-Add {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 7px;
    }
    &-Plus {
        display: none;
    }
    &-Share {
        bottom: 1px;
    }
}

@include mobile {
    .InstallPromptIOS {
        &-Heading {
            margin: 4px 0 9px 0;
        }
    }
}
