

.ButtonLanding {
    @include button();
    padding: 12px 48px;
    font-family: "filson-soft",sans-serif;
    font-weight: 500;

    &_big {
        min-height: 56px;
        line-height: 54px;
        padding: 0 48px;
    }

    &_priority_primary {
        --button-bg: #{$nature};
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #528463;
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }

    &_advanced {
        --button-bg: #{$advanced};
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #{$advanced70};
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }

    &_advanced_empty {
        --button-bg: transparent;
        --button-border-color: #{$advanced};
        color: $advanced;
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #{$advanced70};
                    --button-border-color: #{$advanced70};
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }

    &_vital {
        --button-bg: #{$vital};
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #{$vital70};
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }

    &_marine {
        --button-bg: #{$marine};
        --button-border-color: var(--button-bg);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include after-mobile {
                    --button-bg: #{$marine_secondary};
                }
            }
        }
        &[disabled] {
            --button-bg: #EDF0F3;
            --button-color: #AAAFB6;
        }
    }
}
