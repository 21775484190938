$p-color: #5E6875;

.TypographyParagraph {
    color: $p-color;
    font-weight: 300;
    line-height: 150%;
    font-family: "filson-pro", sans-serif;

    &_color_white {
        color: $neutral5;
    }

    &_color_black {
        color: $neutral90;
    }

    &_color_blue {
        color: $primary90;
    }

    &_light_gray {
        color: $neutral70;
    }

    &_color_nature {
        color: $nature;
    }

    &_color_vital {
        color: $vital;
    }

    &_color_eco {
        color: $blue1;
    }

    &_size_small {
        font-size: 13px;
    }
    &_size_normal, &_size_normal p {
        font-size: 15px;
    }
    &_size_big, &_size_big p {
        font-size: 18px;
    }
    &_size_large, &_size_large p {
        font-size: 19px;
    }

    &_align_center {
        text-align: center;
    }

    &_italic {
        font-style: italic;
    }

    &_light {
        font-weight: 100;
    }

    &_mb_0 {
        margin-bottom: 0;
    }
}
