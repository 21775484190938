/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

:root {
    --input-border-color: #e0e0e0;
    --input-border-color-focus: #{$black};
    --input-background-color: #fff;
    --input-padding: 13px 20px;
    --input-color: var(--primary-base-color);
}

input,
textarea,
select {
    @extend .TypographyParagraph;
    @extend .TypographyParagraph_size_normal;
    border: 1px solid transparent;
    background-color: $neutral5;
    appearance: none;
    border-radius: 100px;
    height: 56px;
    padding-left: 32px;
    @include placeholder {
        color: $neutral60;
    }
    &:focus, &:active {
        caret-color: $primary90;
        @include placeholder {
            color: $primary90;
        }
        background-color: $primary5;
    }
    &[disabled] {
        background: $neutral20;
        color: $neutral50;
        cursor: not-allowed;
        border-color: transparent;
    }
}

textarea {
    width: 30rem;
    border-radius: 30px;

    @include mobile {
        width: 35rem;
    }
}

[type='number'] {
    width: var(--input-type-number-width);
    font-weight: 700;
    padding-right: 0;
    -moz-appearance: textfield;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;
        width: 1.8rem;
        font-size: 1.8rem;
        line-height: 0;

        @include mobile {
            width: 2.1rem;
            font-size: 2.1rem;
        }

        &:first-of-type {
            @include plus-icon;
        }

        &:last-of-type {
            @include minus-icon;
            bottom: 0;
        }

        &:first-of-type,
        &:last-of-type {
            height: 50%;
        }

        &:disabled {
            opacity: .2;
            cursor: not-allowed;
        }
    }
}
