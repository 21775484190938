.QuoteCMS {
    &-Pill {
        margin: -324px auto auto;
        max-width: 730px;
        box-shadow: 0 40px 80px rgba(82, 149, 200, 0.18);
        border-radius: 500px;
        background-color: $neutral5;
        background-image: url("../../assets/svg/pill-decoration.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;

        h3 {
            padding: 100px 0;
            margin: 0 auto;
            max-width: 340px;
            text-align: center;
            color: $neutral90;
        }

        p {
            margin-bottom: 0;
            color: $neutral70;

        }

        &_Dot {
            position: absolute;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
                flex-direction: row-reverse;
                left: -112px;
                top: calc(50% - 14px);

                .dot {
                    margin: auto 0 auto 20px;
                }
            }

            &:nth-child(2) {
                left: 6.2%;

                .dot {
                    margin: auto 20px auto 0;
                }
            }

            &:nth-child(3) {
                left: 65%;
            }


            &:nth-child(2), &:nth-child(3) {
                flex-direction: column-reverse;
                top: -55px;

                .dot {
                    margin: 20px auto 0 auto;
                }
            }

            &:nth-child(4) {
                right: -176px;
                top: calc(50% - 14px);

                .dot {
                    margin: auto 20px auto 0;
                }
            }

            &:nth-child(5) {
                left: 64%;
            }

            &:nth-child(6) {
                left: 36.3%;
            }

            &:nth-child(7) {
                left: 4.8%;
            }

            &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                flex-direction: column;
                bottom: -55px;
                width: 200px;
                text-align: center;

                .dot {
                    margin: 0 auto 20px auto;
                }
            }

            .dot {
                width: 14px;
                height: 14px;
                background: $primary5;
                box-sizing: border-box;
                border-radius: 50px;
                border: 1px solid $primary50;
                box-shadow: 0px 8px 10px rgba(102, 171, 217, 0.3);
            }

        }
    }

    &-Top {
        padding: 150px 18% 80px;
    }

    &-Bottom {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include ultra-wide-desktop {
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
        }


        picture{
            justify-self: start;
        }
    }

    &-ParagraphWrapper {
        margin-top: 50px;
        box-sizing: content-box;
        max-width: 600px;

        .TypographyParagraph {
            color: $neutral90;
        }
    }

    &-Quote {
        font-size: 200px;
        color: $primary50;
        font-family: Roboto;
        font-weight: bold;
        line-height: 1;
        margin-bottom: -100px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .Image_ratio_square {
        width: 700px;
        height: 400px;
        padding-bottom: 0;

        .Image-Image {
            position: relative;
            top: 0;
            left: 0;
            width: initial;
            height: initial;
            object-position: initial;
            object-fit: initial;

            img {
                width: 100%;
            }
        }
    }
}

@include tablet {

    .QuoteCMS {
        &-Pill {
            max-width: 275px;
            height: 470px;
            margin-top: -390px;

            h3 {
                padding: 165px 20px;
            }

            &_Dot {
                p {
                    max-width: 200px;
                }

                &:nth-child(1) {
                    flex-direction: column-reverse;
                    left: 136px;
                    top: -48px;
                    .dot {
                        margin: 20px auto 0 auto;
                    }
                }

                &:nth-child(2), &:nth-child(3) {
                    left: initial;
                    flex-direction: row;
                    .dot {
                        margin: auto 20px auto 0;
                    }
                }

                &:nth-child(2) {
                    right: -226px;
                    top: 100px;
                }

                &:nth-child(3) {
                    right: -146px;
                    top: 300px;
                }

                &:nth-child(4) {
                    right: -112px;
                    top: 435px;
                    .dot {
                        margin: auto 20px auto 0;
                    }
                }

                &:nth-child(5) {
                    left: -144px;
                    bottom: 20px;
                }

                &:nth-child(6) {
                    left: -156px;
                    bottom: 220px;
                }

                &:nth-child(7) {
                    left: -144px;
                    bottom: 422px;
                }

                &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                    flex-direction: row-reverse;
                    .dot {
                        margin: auto 0 auto 20px;
                    }
                }
            }
        }

        &-Bottom {
            grid-template-columns: 1fr;
        }

        &-ParagraphWrapper {
            justify-self: end;
            max-width: 472px;
            margin-top: -50px;
            padding: 0 24px 0 0;
        }
    }
}

@include mobile {
    .QuoteCMS {
        position: relative;
        &-Pill {
            width: 80%;
            max-width: 275px;
            height: 470px;
            margin-top: -272px;

            h3 {
                position: absolute;
                padding: 0;
                margin-top: -150px;
                margin-left: calc(50% - 155px);
                width: 300px;
            }

            &_Dot {
                p {
                    font-size: 15px;
                }

                &:nth-child(1) {
                    top: -44px;
                }

                &:nth-child(2) {
                    top: 100px;
                }

                &:nth-child(3) {
                    top: 300px;
                }


                &:nth-child(2), &:nth-child(3) {
                    right: -8px;
                    p {
                        text-align: right;
                    }

                    .dot {
                        margin: 20px 0 0 auto;
                    }
                }

                &:nth-child(4) {
                    right: 2px;
                    top: 446px;
                }

                &:nth-child(5) {
                    left: -58px;
                    bottom: -15px;
                }

                &:nth-child(4), &:nth-child(5) {
                    flex-direction: column;
                    .dot {
                        margin: 0 auto 20px auto;
                    }
                }

                &:nth-child(6) {
                    left: -100px;
                }

                &:nth-child(7) {
                    left: -66px;
                }

                &:nth-child(6), &:nth-child(7) {
                    .dot {
                        margin: 20px auto 0 auto;
                    }
                }

                &:nth-child(1), &:nth-child(2), &:nth-child(3),  &:nth-child(6), &:nth-child(7) {
                    flex-direction: column-reverse;
                }
            }
        }

        &-Top {
            padding: 100px 10px;
        }

        &-Bottom {
            padding: 50px 0;

            picture{
                justify-self: end;
            }
        }

        &-ParagraphWrapper {
            padding: 0 20px;
            margin-top: 0;
        }
    }
}

.PseudoSlider {
    height: 500px;
    background: $primary5;
}
