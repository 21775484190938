/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.GroupOfAttributes {
    @include container;

    @include mobile {
        padding: 0;
    }

    &-Wrapper {
        display: flex;
        background: $primary5;
        border-radius: 16px;
        flex-direction: column;

        @include desktop {
            border-radius: 30px;
            flex-direction: row;
        }
    }

    &-Heading {
        display: flex;
        align-items: start;
        text-align: left;
        padding: 24px 20px 0;
        width: 100%;

        @include desktop {
            padding: 53px 96px;
            width: 30%;
            border-right: 2px solid $white;
            text-align: left;
            justify-content: center;
            align-items: start;
        }

        .TypographyHighlight {
            color: $primary50;
            display: initial;

            @include desktop {
                display: block;
            }
        }

        .TypographyHeader {
            margin: 0;

            @include desktop {
                white-space: nowrap;
            }
        }
    }

    &-Attributes {
        width: 100%;
        padding: 32px 20px;

        @include desktop {
            width: 70%;
            padding: 53px 74px;
        }
    }

    &-SingleAttribute {
        padding: 40px 0;
        max-width: 780px;
        border-bottom: 1px solid $primary20;

        @include tablet {
            padding: 32px 0;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .TypographyHeader,
        .TypographyParagraph {
            letter-spacing: normal;
        }

        .TypographyHeader {
            margin: 0 0 8px;
            font-weight: 500;
            font-family: $font-filson-soft;

            @include tablet {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .TypographyParagraph, .TypographyParagraph p {
            color: $neutral80;
            line-height: 22px;
        }
    }
}
