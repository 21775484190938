.HeaderWithTilesCMS {
    padding-bottom: 100px;

    &-Heading {
        text-align: center;
        max-width: 770px;
        margin: auto;
    }

    &-ArticleList {
        display: flex;
        margin: 50px auto;
        padding: 0 85px;
        justify-content: space-between;
        max-width: 1440px;
    }

    &-ArticleTile {
        width: 30%;
        max-width: 370px;
        .TypographyParagraph_size_big {
            height: 54px;
        }

        &_Img {
            height: auto;
            padding-bottom: 20px;
        }

        &_Link {
            color: $primary50;
        }
    }
}

@include tablet {
    .HeaderWithTilesCMS {
        &-ArticleList {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 75px;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &-ArticleTile {
            width: 370px;
        }
    }
}

@include mobile {
    .HeaderWithTilesCMS {
        &-ArticleList {
            padding: 0 25px;
        }

        &-ArticleTile {
            width: 225px;
            .TypographyParagraph_size_big {
                height: 81px;
            }
        }
    }
}
