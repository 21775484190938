/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.SearchField {
    @include desktop {
        width: 100%;
        max-width: 100%;
    }
    display: block;

    &-Input{
        height: 40px;
        padding-left: 50px;
        box-shadow: 0 15px 40px rgba(82, 149, 200, 0.18);
        &_isActive, &:active, &:focus{
            background-color: #fff;
        }
        &_isNavigationSearch {
            width: 180px;
            border: 1px solid #9EC5DF;
            box-shadow: none;
        }
    }

    &-CloseIcon, &-SearchIcon{
        right: unset;
        left: 15px;
    }

    &-SearchIcon {
        cursor: pointer;
    }
}
