.CMSList {
    max-width: 544px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    &-Item {
        display: flex;
        padding-bottom: 27px;
        &Tick {
            padding-left: 45px;
            padding-bottom: 16px;
            background-image: url("../../assets/svg/vector-green.svg");
            background-repeat: no-repeat;
            height: 24px;
            background-position-y: bottom;
        }
    }
}

@include tablet {
    .CMSList {
        max-width: 426px;
    }
}

@include mobile {
    .CMSList {
        max-width: 568px;
    }
}
