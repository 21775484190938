/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

 .BlogRelatedPosts.BlogRelatedPosts_isOverridden {
     @include container;

     .BlogRelatedPosts {
         &-Wrapper {
             border-top: 1px solid $primary20;
             padding-top: 64px;
         }

         &-PostsWrapper {
             grid-template-columns: 1fr;
             grid-gap: 24px;

             @include desktop {
                 grid-template-columns: repeat(3, 1fr);
             }
         }

         &-Title {
             margin: 0 0 32px;
             font-size: 30px;
             font-weight: 500;
             line-height: 45px;
             text-align: center;
             border: none;
             width: auto;
         }
     }
 }
