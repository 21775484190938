.VerticalContent {
    padding: 50px 0;
    box-sizing: border-box;
    background-image: url("../../assets/svg/vertical-content-bg.svg");
    background-repeat: no-repeat;
    background-position-x: right;

    &-Heading {
        max-width: 600px;
        text-align: center;
        margin: 50px auto;
        &-Span {
            color: $primary90;
        }
    }

    .VerticalContentTabs {
        max-width: 1440px;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        &-Tabs {
            width: 30%;
            height: 520px;
            display: flex;
            padding: 72px 3% 72px 40px;
            background: $primary5;
            flex-direction: column;
            gap: 20px;
        }

        &-Tab {
            position: relative;
            cursor: pointer;
            color: $neutral50;
            transition: color .3s ease-in-out;
            text-align: left;
            width: 290px;
            margin-left: auto;
            text-transform: uppercase;

            &::after {
                content: '';
                display: block;
                width: 5px;
                height: 28px;
                border-radius: 100px;
                background-color: var(--tab-strip);
                position: absolute;
                bottom: calc(50% - 14px);
                left: -18px;
                transform: scaleX(0);
                transform-origin: left;
                transition: transform .3s ease-in-out;
            }

            &:hover, &_isActive {
                color: $neutral90;
                &::after {
                    transform: scaleX(1);
                }
            }
        }

        &-Content {
            margin-top: 45px;
            display: none;
            &_isActive {
                display: block;
            }
        }

        &-Box {
            max-width: 820px;
            box-sizing: border-box;
            padding: 60px 20px;
            margin: 0 85px;
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid $primary10;

            &-Info {
                max-width: 422px;
                min-width: 200px;
                margin: auto 60px auto 0;
                h4 {
                    text-transform: uppercase;
                }
            }

            &-Img {
                min-width: 250px;
                max-width: 290px;
                height: auto;
            }
        }
    }
}

@include tablet {
    .VerticalContent {
        background-image: none;

        .VerticalContentTabs {
            &-Tabs {
                padding: 65px 40px;
                height: 830px;
            }

            &-Tab {
                width: 100%;
            }

            &-Box {
                flex-direction: column-reverse;
                padding-left: 0;
                margin: 60px 44px;
                margin-right: 0;

                &-Info {
                    margin: 30px 20px 0 0;
                }
            }
        }
    }
}
@include mobile {

    .VerticalContent {
        .VerticalContentTabs {
            display: block;

            &-Box {
                padding-top: 0;
                padding-bottom: 40px;
                margin-top: 40px;
                margin-bottom: 0;
            }

            &-Tabs {
                display: none;
            }

            &-Content {
                display: block;
            }
        }
    }
}

