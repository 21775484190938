.LpPrimaryBlog {
    margin-block: 70px 130px;
    padding-inline: 24px;

    &-Content {
        .LpPrimaryHeadingContainer .TypographyHeader {
            margin-bottom: 16px;
        }
    }

    &-Wrap {
        max-width: 950px;
        margin: 0 auto;
    }

    &-Image {
        max-width: 763px;
        justify-self: end;
    }

    &-ShowMore {
        text-align: center;

        & > * {
            @include tablet {
                width: 100%;
            }
        }
    }

    &-Articles {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: 1fr;
        margin-bottom: 24px;

        @include before-mobile {
            grid-template-columns: repeat(2,1fr);
        }

        @include desktop {
            grid-template-columns: repeat(3,1fr);
            grid-gap: 24px;
        }
    }

    &-ArticleImage {
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 26px;
    }
}
