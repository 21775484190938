/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LotTable {
    max-width: 900px;
    margin: 0 auto;
    font-family: "filson-soft",sans-serif;

    @include desktop {
        width: 100%;
    }

    @include tablet {
        min-width: 100%;
    }

    @include mobile {
        min-width: 100%;
    }

    &-Wrapper {
        max-width: 100%;
        overflow: auto;
    }

    tr {
        border-top: 1px solid $neutral50;

        &:not(:last-child) {
            border-bottom: none;
        }

        &.LotTable-Row_Heading,
        &.LotTable-Row_SubHeading {
            border: none;
        }

        &.LotTable-Row_SubHeading {
            & + tr {
                border: none;
            }
        }
    }

    th,
    td {
        vertical-align: top;
        text-align: center;
    }

    thead th,
    tbody td {
        width: 22%;
        background: $white;

        &:first-child {
            background: $default-secondary-light-color;
            width: 36%;
        }

        &:nth-child(3) {
            background: $primary5;
        }
    }

    td {
        width: 22%;

        &:first-child {
            text-align: left;
        }

        &:nth-child(2) {
            text-align: left;
        }
    }

    &-Tooltip {
        position: relative;
        cursor: pointer;

        &:hover {
            &::after {
                display:block;
            }
        }

        &::after {
            content: attr(data-tooltip);
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            transition-delay: 250ms;
            transition-duration: 100ms;
            left: 100%;
            margin-left: 20px;
            background-color:#FFFFFF;
            border: 1px solid #9EC5DF;
            color: #000000;
            text-align: left;
            box-shadow: 0 0 80px rgba(82, 149, 200, 0.18);
            padding: 15px 30px;
            width: max-content;
            max-width: 500px;
            font-size: 15px;
            z-index: 1000;
            word-break: break-word;

            display: none;
        }
    }

    &-Row {
        &_Heading {
            th {
                font-size: 17px;
                font-weight: 300;
                text-transform: uppercase;
            }
        }

        &_SubHeading {
            background: $primary30;

            th {
                font-size: 17px;
                font-weight: 600;
                text-align: left;
                padding: 5px 10px;
            }
        }

        &_Last {
            td {
                padding-bottom: 25px;
            }
        }
    }

    &-Cell {
        font-size: 14px;
        font-weight: 300;

        &_type {
            &_value {
                color: $nature50;
                font-weight: 600;
            }
        }
    }
}
