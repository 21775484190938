@import './lp-eco-hero.scss';
@import './lp_eco-clean-flake.scss';
@import './lp_eco_bio_pet.scss';
@import './lp_eco_goed.scss';
@import './lp_eco_ingredients.scss';
@import './lp_eco_tested.scss';
@import './lp_eco_ingredients.scss';
@import './lp_eco_by_specialists.scss';
@import './lp_eco_quality.scss';
@import './lp_eco_info.scss';

.LpEco {
  &-SubTitle {
    color: $blue1;
    text-align: center;
    font-family: "filson-soft", sans-serif;
    font-size: 34px;
    font-weight: 500;
    line-height: 133%;

    @include tablet {
      font-size: 24px;
    }
  }

  &-Decoration {
    &::before {
      display: none;
      content: '';
      width: 551px;
      height: 632px;
      position: absolute;
      left: 0;
      top: 45px;
      background: url('../../../../assets/images/lp_eco_decoration_1.png') 0 0 no-repeat;
      @include desktop {
        display: block;
      }
    }

    &::after {
      display: none;
      content: '';
      width: 350px;
      height: 680px;
      position: absolute;
      right: 0;
      top: 280px;
      background: url('../../../../assets/images/lp_eco_decoration_2.png') 0 0 no-repeat;
      @include desktop {
        display: block;
      }
    }
  }

  &-List {
    li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 0;
      font-family: "filson-soft", sans-serif;
      font-weight: 350;
      font-size: 19px;
      line-height: 120%;

      &::before {
        display: inline-block;
        content: '\2022';
        margin-left: -20px;
        color: $blue1;
        font-size: 40px;
        line-height: 1px;
        vertical-align: -3px;
      }
    }

    li + li {
      margin-top: 28px;
    }
  }
}
