.OfferCMS {
    background-color: $primary5;

    &-Wrapper {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
    }

    &-ImageWrapper {
        display: flex;
        width: 530px;

        &-Graphics {
            max-width: 512px;
            img {
                height: auto;
            }
        }
    }

    &-ContentWrapper {
        margin: auto 25px;
        max-width: 546px;
        padding: 25px 0;

        &-Content {
            padding-bottom: 30px;
        }
    }
}

@include tablet {
    .OfferCMS {
        &-ImageWrapper-Graphics {
            width: 400px;
        }

        &-ContentWrapper {
            &-Content {
                padding-bottom: 30px;
            }
            .TypographyHeader_heading_h1 {
                margin-bottom: 35px;
            }
        }
    }
}

@include mobile {
    .OfferCMS {
        &-ImageWrapper {
            display: none;
        }

        &-ContentWrapper {
            padding: 45px 0;
            display: flex;
            flex-direction: column;
            .Button {
                margin: 0 auto;
            }
        }
    }
}
