.LpPrimaryVariousForms {
    margin-top: 64px;

    @include desktop {
        margin-top: 154px;
    }

    h2 {
        margin-top: 0;
    }

    .LpPrimaryHeading2 {
        padding-bottom: 32px;
    }

    &-SubHeader {
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: none;

        @include desktop {
            font-size: 30px;
            font-weight: 600;
            line-height: 45px;
        }

        & > span {
            font-size: 30px;
            font-weight: 700;
            line-height: 45px;

            @include desktop {
                font-size: 44px;
                font-weight: 700;
                line-height: 53%;
            }
        }
    }

    img {
        width: auto;
        max-width: 100%;
        display: inline;
    }

    &-Image {
        text-align: center;
        @include tablet {
            display: none;
        }
    }

    &-ImageSmall {
        display: none;
        text-align: center;
        @include tablet {
            display: block;
        }
    }
}
