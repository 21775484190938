/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TeamPage {
    font-family: $font-filson-soft;
    padding: 100px 0 200px;

    @include tablet {
        padding: 70px 0 150px;
    }

    @include mobile {
        padding: 70px 0 100px;
    }

    &-Wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        min-height: 300px;

        @include tablet {
            max-width: 760px;
        }

        @include mobile {
            max-width: 460px;
        }
    }

    &-Heading {
        text-align: center;
        margin-bottom: 100px;
        cursor: default;

        @include mobile {
            margin-bottom: 60px;
        }

        h2 {
            font-size: 36px;
            font-weight: 500;

            @include mobile {
                font-size: 32px;
            }
        }
        span {
            color: $primary50;
        }
    }

    &-Grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;
        }

        @include mobile {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }

    &-Item {
        background: $primary5;
        text-align: center;
        padding: 30px;
        cursor: default;

        &Image {
            border-radius: 50%;
            overflow: hidden;
            text-align: left;
            background: $white;
            margin: 0 auto;

            @include tablet {
                max-width: 240px;
            }
        }

        &Name {
            font-size: 24px;
            font-weight: 500;
            color: $primary50;
            margin-top: 30px;
        }

        &Position {
            font-size: 18px;
            margin-top: 12px;
            font-weight: 400;
        }

        &Description {
            p {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                color: $neutral60;
                margin-top: 8px;
            }
        }
    }
}
