/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding: 9px 12px;
    margin: 0;
    transition: all 0.2s ease;

    &:not(&:last-child) {
        margin-bottom: 16px;
    }

    &:hover {
        box-shadow: 0px 0px 20px 0px #{rgba($black, 0.15)};

        .SearchItem-Title {
            color: $primary90;
        }
    }

    &::before {
        content: none;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: .5;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 48px calc(100% - 60px);
        grid-column-gap: 12px;
        align-items: center;
    }

    &-Title {
        overflow: hidden;
        font-family: $font-filson-soft;
        font-size: 17px;
        font-weight: 350;
        line-height: 26px;
        transition: color 0.2s ease;
    }
}
