/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ReviewStar {
    --star-outline-color: #F7A912;

    // reset from input default styles
    display: inline;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    height: 24px;
    width: 24px;
    min-width: initial;

    transition: color 150ms ease-in;
    color: $white;
    text-shadow:
        -1px 0 var(--star-outline-color),
        0 1px var(--star-outline-color),
        1px 0 var(--star-outline-color),
        0 -1px var(--star-outline-color);

    font-size: 24px;
    line-height: 1;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: Times; // make sure ★ appears correctly

    &::after {
        content: '★';
        font-family: serif;
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        color: var(--star-outline-color);
    }

    &:active,
    &:focus {
        background-color: transparent;
        caret-color: transparent;
        border: 0;
    }
}
