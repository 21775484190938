/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Menu {
    &-ItemCaption {
        white-space: nowrap;
    }

    &-MenuWrapper {
        height: var(--header-height);
        display: flex;
        align-items: center;
        margin-right: 36px;
    }

    &-ItemList {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_type_subcategory {
            flex-direction: column;
            align-items: flex-start;
            padding: 32px;
            margin-right: 0;
            .Menu-Link{
                font-weight: 400;
            }
        }
    }

    &-Item {
        padding: 0;
        margin-bottom: 0;
        margin-right: 65px;

        &::before {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    &-Link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "filson-soft", sans-serif;
        color: $neutral90;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.32px;
        line-height: 1;
        transition: color .3s ease-in-out;

        &:hover, &:focus {
            color: $primary90;
            text-decoration: none;
        }
    }

    &-SubCategoriesWrapper {
        @include submenuWrapper;
        display: none;
        &_isVisible {
            display: block;
        }
    }

    &-SubItemWrapper {
        .Menu-ItemList{
            flex-direction: column;
            align-items: flex-start;
        }

        .Menu-Link {
            margin-left: 0;
            font-weight: 350;
            line-height: 150%;
            font-size: 15px;
            color: $neutral80;

            &:hover {
                color: $primary90;
            }

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &-SubLevelDesktop {
        margin-top: 15px;
        margin-left: 10px;

        .Menu-Link{
            font-size: 14px;
            font-weight: 300;
            color: $neutral90;

            &:hover {
                color: $primary90;
            }
        }
    }

}
