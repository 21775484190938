.LpPrimaryDer {
    padding-inline: 24px;
    margin-top: 24px;

    &-Arrow {
        position: absolute;
        left: 50%;
        z-index: 1;
        bottom: 0;
        width: 48px;
        height: 113px;
        transform: translate(-50%, 70%);
        background-image: url("../../../../assets/svg/lp_nature_arrow.svg");
    }

    @include desktop {
        margin-top: 40px;
    }

    &-Wrap {
        @include container;

        @include mobile {
            padding-inline: 24px;
        }

        padding-inline: 48px;
        border-radius: 30px;
        padding-block: 48px;
        background-color: $nature50;
        background-image: url("../../../../assets/svg/lp_nature_der_mobile_background.svg");
        background-position: right bottom;
        background-repeat: no-repeat;

        @include desktop {
            padding-block: 133px;
            background-image: url("../../../../assets/svg/lp_nature_der_desktop_background.svg");
        }
    }

    .LpPrimaryGridSpecial {
        @include tablet {
            gap: 48px;
        }
    }

    &-Image {
        justify-self: end;

        img {
            max-height: 73px;
            margin-bottom: 43px;
        }
    }

    &-Content {
        @include desktop {
            max-width: 580px;
        }

        .TypographyHeader {
            margin-block: 0 16px;

            & > span {
                line-height: inherit;
            }
        }
    }
}
