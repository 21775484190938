/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StoreSwitcher {
    &-Link {
        font-family: "filson-soft", sans-serif;
        color: $neutral90;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        transition: color .3s ease-in-out;

        &:not(:last-of-type){
            margin-bottom: 20px;
        }

        &:hover, &:focus{
            color: $primary90;
            text-decoration: none;
        }
    }

    &-Title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "filson-soft", sans-serif;
        color: $neutral90;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        cursor: pointer;
    }

    &-ArrowIcon{
        display: block;
        margin-left: 10px;
        margin-bottom: 3px;
        transform: rotate(0);

        &_isOpened {
            transform: rotate(180deg);
        }
    }

    &-StoreList {
        @include submenuWrapper;
        display: none;
        padding: 20px;
        white-space: nowrap;
        left: 0;

        &_isOpen {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@include tablet {
    .StoreSwitcher {
        &-Title{
            color: #fff;
        }

        &-StoreList {
            top: unset;
            bottom: 30px;
            right: unset;
            left: 50%;
        }
    }
}
