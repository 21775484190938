.LpReseller {
    background-image: url("../../../../assets/images/reseller-bg.png");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;

    @include desktop {
        background-size: auto;
    }

    .TypographyHeader {
        letter-spacing: normal;
    }

    .Button {
        font-weight: 600;
    }

    &-Header {
        @include container;

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 64px;
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 64px;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 144px;
            padding-top: 185px;
            padding-bottom: 179px;
        }

        .Hero-Content {
            text-align: center;
        }

        &Image {
            margin-top: 39px;
            height: auto;
            max-width: 100%;
            width: auto;
        }
    }

    &-Form {
        padding: 32px;
        border-radius: 32px;
        background-color: $vital_secondary;

        @include desktop {
            padding: 64px;
            border-radius: 63px;
        }

        .TypographyHeader {
            margin: 0 0 48px;
            text-align: center;
        }

        .FieldForm-Fields {
            margin-bottom: 0;
        }

        .Field {
            margin-top: 0;

            &:not(:first-child) {
                margin-top: 48px;
            }
        }

        .Field-Label {
            text-align: center;
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 600;
        }

        .ContactForm-Submit {
            margin: 32px 0 0;
            text-align: center;
        }
    }

    &-Footer {
        @include container;

        padding-block: 109px 139px;

        .TypographyHeader, h3 {
            margin: 0;
            font-weight: 300;
        }

        button, a {
            margin-top: 62px;
        }

        text-align: center;
    }
}
