.IntroductionCMS {
    padding-top: 128px;
    padding-bottom: 109px;
    background-size: 745px;
    background-repeat: no-repeat;
    background-position: top -500px right;
    background-image: url('../../assets/svg/introduction-ring.svg');

    &-Text {
        max-width: 850px;
        margin: 0 auto;

        p {
            line-height: 1.5;

            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
        }
    }
}

@include tablet {
    .IntroductionCMS {
        padding: 70px 0;
        background-size: 400px;
        background-position: top -200px right;

        &-Text {
            padding: 0 24px;
        }
    }
}

@include mobile{
    .IntroductionCMS{
        padding: 50px 0;
        background-size: 250px;
        background-position: top -150px right;

        &-Text{
            padding: 0 24px;
        }
    }
}
