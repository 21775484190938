.Hero{
    background-color: $primary5;
    padding-top: 145px;
    padding-bottom: 270px;
    background-size: 992px;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url('../../assets/svg/hero-ring.svg');

    &_noBackgroundColor {
        background-color: transparent;
    }

    &-Grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 66px;
        align-items: center;
        max-width: 1130px;
        margin: 0 auto;
    }

    &-Header{
        margin-bottom: 35px;
    }

    &-Paragraph{
        margin-bottom: 35px;
    }

    &_lessPaddingBottom {
        padding-bottom: 50px;
    }

    &-SmallImage {
        aspect-ratio: 1/1;
    }
}

@include tablet{
    .Hero{
        padding-top: 130px;
        padding-bottom: 302px;
        background-size: 670px;
        background-position: top left -145px;

        &-Grid{
            padding-right: 24px;
            grid-gap: 45px;
        }

        &-Header{
            margin-bottom: 25px;
        }

        &-Paragraph{
            margin-bottom: 25px;
        }

        &_lessPaddingBottom {
            padding-bottom: 50px;
        }
    }
}

@include mobile{
    .Hero{
        padding-top: 80px;
        padding-bottom: 423px;
        background-size: 500px;
        background-position: top left -320px;

        &-Grid{
            grid-template-columns: 1fr;
            padding: 0 24px;
            grid-gap: 30px;
        }

        &-Content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        &_lessPaddingBottom {
            padding-bottom: 50px;
        }
    }
}
