.LpTotox {
    font-family: $font-filson-soft;
    background-image: url("../../assets/images/totox-header-bg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 50%;
    padding-top: var(--header-height, 0px);

    &-HeaderWrapper, &-ProductsWrapper, &-TableWrapper {
        margin-bottom: 64px;

        @include desktop {
            margin-bottom: 100px;
        }
    }

    &-Heading {
        margin: 0 0 40px;
    }

    &-Header {
        display: grid;
        align-items: center;
        grid-gap: 48px;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @include desktop {
            grid-gap: clamp(48px, 15%, 236px);
            grid-template-columns: repeat(1, minmax(0, 1fr)) repeat(1, minmax(0, 486px));
        }

        &Image {
            order: -1;
            max-width: 485px;

            @include desktop {
                order: initial;
            }
        }

        &Wrapper {
            @include container;
        }

        &After {
            max-width: 624px;
            margin: 160px auto 0;

            @include desktop {
                margin: auto auto;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 265px;
                height: 222px;
                transform: translate(-40%, -55%);
                background-image: url("../../assets/images/totox-header-after-bg.png");
                background-repeat: no-repeat;
                background-position: 0 0;
            }
        }
    }

    &-Products {
        display: grid;
        align-items: center;
        grid-gap: 48px 24px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        text-align: center;

        @include desktop {
            grid-gap: 48px;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }


        &Wrapper {
            @include container;

            .LpTotox-Heading {
                text-align: center;
            }
        }
    }

    &-Product {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &:hover {
            .LpTotox-ProductScore {
                background: $primary30;
            }
        }

        &Image {
            margin: 24px 0 40px;
            max-height: 250px;
            max-width: 100%;
            width: auto;
        }

        &Score {
            display: inline-block;
            font-size: 21px;
            font-weight: 600;
            line-height: 80px;
            box-shadow: 0px 10px 35px 0px #5295C833;
            color: $black;
            background: $white;
            border-radius: 100%;
            height: 80px;
            width: 80px;
        }

        &Name {
            font-weight: 350;
            text-align: center;
            margin: 0;
        }
    }

    &-Table {
        width: 100%;
        table-layout: auto;

        &_marine {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: #AB9DD099;
                    }
                }
            }
        }

        &:not(&_marine) {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: $primary20;
                    }
                }
            }
        }

        th, td {
            min-width: 240px;
        }

        th {
            padding: 0 16px 24px;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            background: $white;

            &:first-child {
                text-align: left;
            }
        }

        td {
            padding: 8px 16px;
            font-size: 15px;
            font-weight: 350;
            line-height: 22.5px;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                font-weight: 500;
            }
        }

        &Wrapper {
            @include container;

            .LpTotox-Heading {
                text-align: center;
            }

            .ContentTabs {
                margin: 0 !important;
            }

            .ContentTabs-Tabs {
                justify-content: center;
            }

            .ContentTabs-Tab {
                height: 28px;
                padding: 8px 8px 4px 8px;
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
                background: #91C3EA;
                color: $black;
                margin-right: 32px;

                &::after {
                    background: #0E0F11;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:not(:first-child) {
                    background: #AB9DD0;
                }
            }
        }
    }

    &-PromotedProductsWrapper {
        @include container;

        .LpTotox-Heading {
            text-align: center;
        }
    }
}
