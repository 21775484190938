.LpVitalBlog {
  .LpPrimaryBlog {
    background-color: $vital_secondary;

    .LpPrimaryGridSpecial {
      grid-template-columns: 1.4fr 1fr;
    }

    &-Image {
      max-width: none;
      justify-self: start;
    }

    &-Content {
      margin: 0;
      @include tablet {
        margin: 0 0 60px;
        max-width: none;
        text-align: center;
      }
    }

    .TypographyParagraph {
      @include tablet {
        text-align: center;
      }
    }
  }
}