.ArticlesOpinion {
    max-width: 1056px;
    margin: 0 auto 120px;

    &-Header {
        text-align: center;
        margin-bottom: 60px;
        margin-top: 0;

        strong {
            color: $primary50;
            font-weight: inherit;
        }
    }

    .Image {
        &-Wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            margin: 0 15px;
            padding: 0 20px;

            a {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: auto;
                max-height: 100%;
                max-width: 100%;
                filter: grayscale(100%);
                opacity: .5;
                transition: filter .2s linear, opacity .2s linear;

                @include mobile {
                    max-width: 80%;
                }
            }

            &:hover {
                img {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }
        }
    }
}
