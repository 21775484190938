/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogPostCard.BlogPostCard_isOverridden {
    max-width: unset;
    max-height: unset;
    margin: 0;

    &:hover {
        .BlogPostCard-ImageWrapper {
            img {
                transition: transform 200ms ease;
                transform: scale(1.05);
            }
        }
    }

    &:not(:hover) {
        .BlogPostCard-ImageWrapper {
            img {
                transform: scale(1);
            }
        }
    }

    &.BlogPostCard_isHero {
        .BlogPostCard {
            &-Title {
                font-size: 30px;
                line-height: 45px;
            }

            &-ContentWrapper {
                align-items: center;
                grid-gap: 66px;
            }

            &-ImageWrapper {
                order: -1;

                img {
                    @include desktop {
                        border-radius: 30px;
                    }
                }
            }
        }
    }

    .BlogPostCard {
        &-ContentWrapper {
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;
            align-content: flex-start;
            grid-gap: 25px;

            @include desktop {
                grid-template-columns: 1fr 0.7fr;
            }

            &_isRelated {
                grid-template-columns: 1fr;
            }
        }

        &-ImageWrapper {
            display: block;

            @include tablet {
                order: -1;

                &_isRelated {
                    display: block;
                }
            }

            .Image {
                overflow: hidden;
                border-radius: 15px;
                padding-bottom: 66.66%;

                img {
                    transition: transform 200ms ease;
                    object-fit: cover;
                }
            }

            &_isRelated {
                order: -1;
            }
        }

        &-Details {
            position: static;
            padding: 0;
            margin: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 16px;

            &_isRelated {
                padding: 0 16px;
            }
        }

        &-Header {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 350;
            color: $neutral70;

            &Separator {
                padding-inline: 12px;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                color: inherit;
            }
        }

        &-ReadingTime {
            svg {
                margin-right: 6px;
            }
        }

        &-PublishDate, &-Author {
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
        }

        &-Title {
            color: $black;
            margin: 0;
            padding: 0;
            text-transform: none;
            font-weight: 500;
            font-size: 21px;
            line-height: 30px;

            &_isRelated {
                font-size: 21px;
                font-weight: 500;
                line-height: 30px;
                text-align: left;
            }
        }

        &-Categories {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;

            font-size: 12px;
            font-weight: 350;
            line-height: 18px;
            color: $primary90;
        }

        &-Category {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;

            &Separator {
                padding-inline: 12px;
            }
        }

        &-Description {
            margin-bottom: 0;

            @include line-clamp($font-size: 15px, $lines-to-show: 5);

            &, p {
                color: $neutral70;
                font-size: 15px;
                line-height: 1.4;
                font-weight: 300;
            }
        }

        &-Button {
            display: inline-block;
            margin-left: auto;
            margin-right: 30px;
            font-size: 16px;
            font-weight: 400;
            font-family: $font-filson-soft;
            padding: 13px 50px;

            @include narrow-desktop {
                font-size: 14px;
                padding: 10px 30px;
                margin-right: 0;
            }

            @include tablet {
                margin-left: 0;
                margin-right: 0;
                font-size: 14px;
                padding: 10px 30px;
            }

            @include mobile {
                margin-left: auto;
            }

            &:hover {
                background-color: $primary80;
            }
        }
    }
}
