/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ResetAttributes {
    @include desktop {
        display: inline-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin-block: 16px;
    }

    &-Section {
        margin-right: 16px;
        margin-bottom: 16px;

        &Title {
            font-family: 'filson-soft', sans-serif;
            font-size: 13px;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
    }

    @include mobile {
        margin: 16px;
        margin-block-end: 0;
    }

    &-AttributeValue {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 8px 4px 12px;
        background-color: $primary5;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &-AttributeText{
        font-family: 'filson-pro', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        padding: 0;
    }

    &-CloseIcon {
        margin-left: 4px;
        .CloseIcon {
            height: 22px;
            width: 22px;
            fill: $primary90;
            &:hover{
                fill: $neutral90;
            }
        }
    }

    &-Title {
        @include mobile {
            display: none;
        }

        @include desktop {
            margin: 0;
            font-family: 'filson-soft', sans-serif;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-inline-start: 16px;
            font-weight: 700;
            padding-block-start: 20px;
        }

        @include desktop {
            display: none;
        }
    }
}
