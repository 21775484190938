:root {
    --tab-color: #{$neutral50};
    --tab-strip: #{$nature50};
    --box-content-padding: 40px 13px 0 13px;
    --box-min-width: 375px;
    --image-mask-color: #{$primary50};
}

.ContentTabs {
    padding: 0 84px;
    max-width: 1440px;
    margin: 150px auto;

    &-Tabs {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: auto;
        overflow-x: auto;
    }

    &-Tab {
        position: relative;
        margin-right: 82px;
        cursor: pointer;
        color: var(--tab-color);
        transition: color .3s ease-in-out;

        &::after {
            content: '';
            display: block;
            width: 41px;
            height: 7px;
            border-radius: 100px;
            background-color: var(--tab-strip);
            position: absolute;
            bottom: -13px;
            left: 0;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .3s ease-in-out;
        }
        &:hover, &_isActive {
            --tab-color: #{$neutral90};
            &::after {
                transform: scaleX(1);
            }
        }
    }

    &-Content {
        margin-top: 45px;
        display: none;
        &_isActive {
            display: block;
        }
    }

    &-Grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 75px;
        margin-bottom: 50px;
        overflow-x: auto;
        --box-min-width: 370px;

        &_columns {
            &_4 {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 23px;
                --box-min-width: 300px;
            }

            &_5 {
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 27px;
                --box-content-padding: 40px 0 0 0;
                --box-min-width: 210px;
            }

            &_6 {
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 27px;
                --box-content-padding: 40px 0 0 0;
                --box-min-width: 185px;
            }
        }

        &_goals {
            background-color: rgba(82, 149, 200, 0.10);
            box-shadow: 0px 0px 80px 40px rgba(82, 149, 200, 0.10);
            border-radius: 30px;
            grid-gap: 24px;
        }
    }

    &-Box {
        min-width: var(--box-min-width);

        &-Image {
            img {
                width: auto;
                max-width: 100%;
                vertical-align: bottom;
            }

            &_withMask {
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    mix-blend-mode: color;
                    background-color: var(--image-mask-color);
                    z-index: 1;
                }
                &::before {
                    content: '';
                    display: block;
                    width: 141px;
                    height: 102px;
                    background-image: url('./src/assets/svg/image-decoration.svg');
                    background-position: bottom left;
                    background-size: contain;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                }
            }

            &_mask_purple {
                --image-mask-color: #{$advanced80};
            }

            &_mask_green {
                --image-mask-color: #{$nature70};
            }

            &_goals {
                padding: 10px;
            }
        }
        &-Content {
            padding: var(--box-content-padding);
            &_minHeight {
                min-height: 180px;
            }
            &_goals {
                padding-top: 24px;
            }
        }
        &-Center {
            display: flex;
            justify-content: center;
        }
        &_goals {
            padding: 24px;
            border-radius: 30px;
            background-color: $neutral5;
        }
    }
}

@include tablet {
    .ContentTabs {
        padding: 0 24px;

        &-Tabs {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-Tab {
            white-space: nowrap;
        }

        &-Grid {
            --box-min-width: 287px;
            grid-gap: 25px;
            margin-bottom: 35px;
            &::-webkit-scrollbar {
                display: none;
            }

            &_columns {
                &_4 {
                    --box-min-width: 225px;
                }
            }
        }

        &-Box {
            &-Content {
                --box-content-padding: 40px 13px 0 0;
            }
        }
    }
}

@include mobile {

    .ContentTabs {
        &-Tab {
            margin-right: 32px;
        }

        &-Grid {
            --box-min-width: 256px;

            &_columns {
                &_5 {
                    grid-template-columns: repeat(10, 1fr);
                }

                &_6 {
                    --box-min-width: 195px;
                }
            }
        }
    }
}
