/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --tab-color: #{$neutral50};
    --tab-strip: #{$nature50};
    --box-content-padding: 40px 13px 0 13px;
    --box-min-width: 375px;
    --image-mask-color: #{$primary50};
}
.ContentTabs{
    padding: 0 84px;
    max-width: 1440px;
    margin: 150px auto;

    @include tablet{
        padding: 0 24px;
    }

    &-Tabs{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: auto;
        overflow-x: auto;
        @include tablet{
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &-Tab{
        position: relative;
        margin-right: 82px;
        cursor: pointer;
        color: var(--tab-color);
        transition: color .3s ease-in-out;
        @include tablet{
            white-space: nowrap;
        }
        @include mobile{
            margin-right: 32px;
        }

        &::after{
            content: '';
            display: block;
            width: 41px;
            height: 7px;
            border-radius: 100px;
            background-color: var(--tab-strip);
            position: absolute;
            bottom: -13px;
            left: 0;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .3s ease-in-out;
        }
        &:hover, &_isActive{
            --tab-color: #{$neutral90};
            &::after{
                transform: scaleX(1);
            }
        }
    }

    &-Content{
        margin-top: 45px;
        display: none;
        &_isActive{
            display: block;
        }
    }

    &-Grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 75px;
        margin-bottom: 50px;
        overflow-x: auto;
        --box-min-width: 370px;

        @include tablet{
            --box-min-width: 287px;
            grid-gap: 25px;
            margin-bottom: 35px;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include mobile{
            --box-min-width: 256px;
        }

        &_columns{
            &_4{
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 23px;
                --box-min-width: 300px;
                @include tablet{
                    --box-min-width: 225px;
                }
            }

            &_5{
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 27px;
                --box-content-padding: 40px 0 0 0;
                --box-min-width: 210px;
                @include mobile{
                    --box-min-width: 220px;
                }
            }

            &_6{
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 27px;
                --box-content-padding: 40px 0 0 0;
                --box-min-width: 185px;
                @include mobile{
                    --box-min-width: 195px;
                }
            }
        }
    }

    &-Box{
        min-width: var(--box-min-width);

        &-Image {
            img{
                width: auto;
                max-width: 100%;
                vertical-align: bottom;
            }

            &_withMask{
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    mix-blend-mode: color;
                    background-color: var(--image-mask-color);
                    z-index: 1;
                }
                &::before{
                    content: '';
                    display: block;
                    width: 141px;
                    height: 102px;
                    background-image: url('./src/assets/svg/image-decoration.svg');
                    background-position: bottom left;
                    background-size: contain;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                }
            }

            &_mask_purple{
                --image-mask-color: #{$advanced80};
            }

            &_mask_green{
                --image-mask-color: #{$nature70};
            }
        }
        &-Content{
            padding: var(--box-content-padding);

            @include tablet{
                --box-content-padding: 40px 13px 0 0;
            }
        }
    }
}
