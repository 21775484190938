/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ContactPage {
    padding-top: 21px;

    @include before-mobile {
        padding-top: calc(var(--header-height) + 21px);
    }

    @include desktop {
        padding-top: calc(var(--header-height) + 62px);
    }

    .ContentWrapper {
        --content-wrapper-width: 1648px;

        @include container;
    }

    &-Header {
        text-align: center;

        .TypographyHeader {
            letter-spacing: normal;
            margin-bottom: 8px;
        }
    }

    &-Content {
        margin-top: 42px;
        margin-bottom: 42px;
        padding: 32px 14px;
        border-radius: 30px;
        background-color: rgba($blue1, 0.12);
        background-image: url("../../assets/svg/contact-page-bg-desktop.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include desktop {
            padding: 92px 62px;
        }
    }

    &-Image {
        text-align: center;

        img {
            max-width: 100%;
            width: auto;
            height: auto;
        }

        &_desktop {
            display: none;

            @include desktop {
                display: block;
            }
        }

        &_mobile {
            display: block;
            margin: 56px auto 24px;

            @include desktop {
                display: none;
            }
        }
    }

    &-ColumnWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        padding: 0;
        margin: 0;

        @include desktop {
            grid-template-columns: 1fr 2fr;
            grid-gap: 150px;
        }
    }

    &-Column_isContent {
        .CmsBlock-Wrapper > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include desktop {
                align-items: flex-start;
                text-align: left;
            }
        }
    }

    .ContactForm {
        background-color: $white;
        border-radius: 30px;
        padding: 24px;

        @include desktop {
            padding: 48px;
        }

        .Button {
            width: 100%;
            margin-top: 20px;
            font-family: "filson-soft",sans-serif;
            font-weight: 500;
        }

        .FieldForm {
            &-Fields {
                display: grid;
                grid-template-columns: 1fr;

                @include desktop {
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 48px;
                }
            }

            .Field-LabelContainer {
                padding-left: 20px;
                padding-bottom: 6px;
            }

            .Field-Label {
                text-align: center;

                @include desktop {
                    text-align: left;
                }
            }

            .Field_type_textarea {
                margin-top: 0;
                margin-bottom: 20px;
            }

            .Field:not(&.Field_type_textarea) {
                margin-top: 0;
                margin-bottom: 42px;
            }

            .Field-Label {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }

            .Field [type='checkbox'] + .input-control, .Field [type='radio'] + .input-control {
                border: 1px solid $neutral50;
            }

            .Field [type='checkbox']:checked + .input-control, .Field [type='radio']:checked + .input-control {
                border-color: $default-primary-base-color;
            }

            input, textarea, select {
                background: $primary5;
            }

            textarea {
                padding-top: 16px;
                min-height: 200px;
            }
        }
    }
}
