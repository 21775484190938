.BenefitsCMS {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 34px;
    border-bottom: solid 1px #e2eef5;

    &-Title {
        text-align: center;
        padding-top: 128px;
    }
    &-ContentWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px;
        margin: 0 53px 63px 42px;
    }
    &-Subtitle {
        max-width: 479px;
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding-top: 18px;
    }
}

@include tablet {
    .BenefitsCMS {
        &-Title {
            font-size: 30px;
        }
        &-Subtitle {
            max-width: 434px;
        }
    }
}

@include mobile {
    .BenefitsCMS {
        &-Title {
            margin-bottom: 12px;
            padding-top: 70px;
            font-size: 26px;
        }
        &-ContentWrapper {
            display: flex;
            flex-direction: column;
        }
        &-Subtitle {
            max-width: 617px;
        }
    }
}
